import {
  Container,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { THEME_KRI } from '../assets/kri/theme';
import Navigations from '../components/navigations/Navigations';
import StickyFooter from '../components/StickyFooter';
import Receipt from '../components/receipt/Receipt';

export default function ReceiptPage() {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <Navigations />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            // height: '90%',
            overflow: 'auto',
          }}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
            <Stack spacing={2}>
              <Grid
                container
                spacing={1}
                justifyContent={'flex-start'}
                direction="column">
                <Grid item>
                  <Typography variant="h4" textAlign={'left'}>
                    Transaction Receipt
                  </Typography>
                </Grid>
                {/*<Grid item sx={{ mt: 2 }}>*/}
                {/*  <Typography textAlign={'left'} variant="body2">*/}
                {/*    Welcome to Kansas Renewal Institute's Patient Payments*/}
                {/*    Portal, powered by Apt Financial*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item sx={{ mt: 2 }}>*/}
                {/*  <Receipt />*/}
                {/*</Grid>*/}
                <Receipt />
              </Grid>
            </Stack>
          </Container>
        </Box>
        <Paper
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#0C415B',
            pt: 2,
            pb: 1,
            '@media (max-width: 600px)': {
              height: '50px',
            },
          }}
          component="footer"
          square
          variant="outlined">
          <StickyFooter />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

