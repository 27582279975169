import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { SessionConfig } from '../api/models';
import { fetchSessionConfig, getClientChannel } from '../api/service';
import { THEME_KRI } from '../assets/kri/theme';
import AdminNavigations from '../components/navigations/AdminNavigations';
import StickyFooter from '../components/StickyFooter';
import { useAuth } from '../context';
import { Alert, Grid, Paper, useMediaQuery, useTheme, SelectChangeEvent } from '@mui/material';
import APTPaymentReport from "../components/apt-reports/APTPaymentReport";

const rfReceiptSmall = {
    textAlign: 'left',
    width: '90%',
};

// TODO - Rename to something else
const rfReceiptRegular = {
    textAlign: 'center',
};

export default function AdminReportPage() {
    const { auth, setAuth } = useAuth();
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const [sessionConfig, setSessionConfig] = useState<SessionConfig>();
    // const [errorMessages, setErrorMessages] = useState([]);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    // const [clientChannelList, setClientChannelList] = useState<any>(null);
    // const [clientChannelError, setClientChannelError] = useState('');
    // const [selectedOption, setSelectedOption] = useState<string>(clientChannelList && clientChannelList[0].client_channel_id);


    const aptServiceFetchSessionConfig = async () => {
        // TODO - Need refactoring
        const clientChannel = auth?.roles[0].split("::")[0];
        setSessionConfig(await fetchSessionConfig(clientChannel));
    };

    const handleOtherErrors = (error) => {
        const errorMessage =
            error.response && error.response.data
                ? `Failed to create establish session with payment gateway: ${error.response.data.message}. Contact support if the problem persists.`
                : `Failed to create establish session with payment gateway. Please try again. Contact support if the problem persists.`;
        setErrorMessage(errorMessage);
    };

    const transactionErrorHandler = (error) => {
        if (error.response && error.response.data) {
            if (error.response.data.status_type === 'TOKEN_EXPIRED') {
                setErrorMessage(
                    `Failed to establish session with payment gateway: ${error.response.data.message}. 
                    You will be redirected to the sign-in page in 5 seconds.`
                );
                setTimeout(() => handleLogout(), 5000);
            } else {
                handleOtherErrors(error);
            }
            return;
        }
        handleOtherErrors(error);
    }

    useEffect(() => {
        setErrorMessage(null);
        if (!sessionConfig) {
            aptServiceFetchSessionConfig()
                .then(() => {
                })
                .catch((error) => transactionErrorHandler(error));
        }
    }, [sessionConfig]);

    const handleLogout = () => {
        setAuth(null);
        localStorage.removeItem('auth')
        window.location.href = '/login';
    };

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    // useEffect(() => {
    //     const fetchClientChannel = async () => {
    //         try {
    //             const clientChannelData = await getClientChannel(auth.id);
    //             setClientChannelList(clientChannelData);

    //             if (clientChannelData.length === 1) {
    //                 setSelectedOption(clientChannelData[0].client_channel_id);
    //             }
    //         } catch (error) {
    //             setClientChannelError(
    //                 error?.response?.data.message
    //                     ? `Failed to fetch client channel ${error?.response?.data.message}`
    //                     : 'An unexpected error occurred'
    //             );
    //         }
    //     };

    //     fetchClientChannel();
    // }, [auth.id]);

    // const handleSelectChange = (e: SelectChangeEvent<string>) => {
    //     setSelectedOption(e.target.value);
    // };

    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Grid
                        container
                        columnSpacing={2}
                        sx={{ padding: 2 }}
                        flexDirection={isSmallerFormFactor ? 'column' : 'row'}
                        justifyContent="center"
                        alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
                        <Grid item xs={isSmallerFormFactor ? false : false}>
                            <Container
                                maxWidth="sm"
                                sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
                                {errorMessage && (
                                    <Alert variant="outlined" severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                            </Container>
                        </Grid>
                    </Grid>
                    {sessionConfig && <APTPaymentReport sessionConfig={sessionConfig}  />}
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
