import {
  Alert,
  Button,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {useEffect, useRef, useState,} from 'react';
import {PayByLinkResponse, PaymentTransaction} from '../../api/models';
import {RFPaymentResponse} from '../../api/rf_models';
import {
  createPaymentTransaction,
  sendPayByLinkForTransaction,
  updatePaymentTransactionForRFValues,
} from '../../api/service';
import {useAuth} from '../../context';
import {FormData} from './APTPaymentWizard';
import {useNavigate} from "react-router-dom";

// const CLIENT_CHANNEL_ID = '19119b31-25cb-4d41-95a9-f90521d24af4';

// TODO - Rename to something else
const rfReceiptSmall = {
  textAlign: 'left',
  width: '90%',
};

// TODO - Rename to something else
const rfReceiptRegular = {
  textAlign: 'center',
};

interface ReviewProps {
  onNext: (
    formData: FormData,
    transactionResponse?: RFPaymentResponse | PayByLinkResponse,
  ) => void;
  onPreviousWithData: (formData: FormData) => void;
  formData: FormData;
  // onUpdatePaymentStatus: (status: string) => void;
  resetForm: () => void;
}

const Review: React.FC<ReviewProps> = ({
  onNext,
  onPreviousWithData,
  formData,
  resetForm
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { auth, setAuth } = useAuth();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
  const [actionDisabled, setActionDisabled] = useState(false);

  const rainforestPayment = useRef(null);
  const initializedRef = useRef(false);

  const [paymentTransaction, setPaymentTransaction] =
    useState<PaymentTransaction>(null);
  const [transactionTableRows, setTransactionTableRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  

  const handlePaymentApproval = async (paymentApproval: any) => {
    setErrorMessage(null);
    const rfTransactionResponse: RFPaymentResponse =
      paymentApproval.detail[0]?.data;

    try {
      await updatePaymentTransactionForRFValues(
        paymentTransaction.payment_transaction.transaction_id,
        rfTransactionResponse
      );
    } catch (error) {
      // This is a fire and forget event if there is exception, webhook will handle this
      console.log(error);
    }
    handleNext(formData, rfTransactionResponse);
  };

  const handlePaymentDeclined = async (rf_response: RFPaymentResponse) => {
    console.log(
      'PaymentDeclined: No need to do anything as RF component taking care of this'
    );
  };

  const handlePaymentError = async (rf_response: RFPaymentResponse) => {
    console.log(
      'PaymentError: No need to do anything as RF component taking care of this'
    );
  };

  const handleLogout = () => {
    console.log("Review logged out");
    setAuth(null);
    localStorage.removeItem('auth');
    navigate('/login');
  };

  const mapTransactionMode = (transaction_mode) => {
    if (transaction_mode && transaction_mode == 'pay-now') {
      return 'PayNow';
    }
    if (transaction_mode && transaction_mode == 'pay-by-link') {
      return 'PayByLink';
    }
    return '';
  };

  const handleOtherErrors = (error) => {
    const errorMessage =
        error.response && error.response.data
            ? `Failed to create payment transaction: ${error.response.data.message}. Contact support if the problem persists.`
            : `Failed to create payment transaction. Please try again. Contact support if the problem persists.`;
    setErrorMessage(errorMessage);
  };

  const transactionErrorHandler = (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.status_type === "TOKEN_EXPIRED") {
        console.log(error.response.data.status_type === "TOKEN_EXPIRED","message")

        setErrorMessage(
            `Failed to create payment transaction: ${error.response.data.message}. You will be redirected to the sign-in page in 5 seconds.`
        );
        setTimeout(() => handleLogout(), 5000);
      } else {
        handleOtherErrors(error);
      }
      return;
    }
    handleOtherErrors(error);
  }

  useEffect(() => {
    if (!initializedRef.current) {
      const fetchData = async () => {
          const response = await createPaymentTransaction({
            client_code: formData.client_code,
            notes: formData.notes,
            email: formData.email,
            amount: formData.amount,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            phone_number: formData.phone_number,
            transaction_mode: formData.transaction_mode,
          },formData.client_channel_id );
          setPaymentTransaction(response as PaymentTransaction);
          setTransactionTableRows([
            { name: 'Client Code', value: formData.client_code },
            {
              name: 'Amount',
              value:
                '$' + parseInt(formData?.amount)
                  ? (parseInt(formData.amount) / 100).toString()
                  : '',
            },
            { name: 'Phone Number', value: formData.phone_number },
            { name: 'Email', value: formData.email },
            { name: 'First Name', value: formData?.first_name },
            { name: 'Last Name', value: formData?.last_name },
            {
              name: 'Payment Method',
              value: mapTransactionMode(formData.transaction_mode),
            },
            { name: 'Notes', value: formData.notes },
          ]);
      };

      if (auth && auth?.accessToken) {
        fetchData()
            .then(() => {})
            .catch((error) => transactionErrorHandler(error));
      }
      initializedRef.current = true;
    }
  }, []);

  useEffect(() => {
    const rfElement = rainforestPayment.current;

    if (rfElement) {
      rfElement.addEventListener('approved', handlePaymentApproval);
      rfElement.addEventListener('declined', handlePaymentDeclined);
      rfElement.addEventListener('error', handlePaymentError);
    }

    return () => {
      if (rfElement) {
        rfElement.removeEventListener('approved', handlePaymentApproval);
        rfElement.removeEventListener('declined', handlePaymentDeclined);
        rfElement.removeEventListener('error', handlePaymentError);
      }
    };
  }, [paymentTransaction]);

  const handlePrevious = () => {
    resetForm(); // Reset form state
    formData.amount = (parseInt(formData.amount) / 100).toString();
    onPreviousWithData(formData);
  };

  const handleSendPayByLink = async () => {
    try {
      setActionDisabled(true);
      const response: PayByLinkResponse = await sendPayByLinkForTransaction(
        paymentTransaction.payment_transaction.customer_id,
        paymentTransaction.payment_transaction.transaction_id,
        formData?.client_channel_id
      );
      
      console.log(response);
      onNext(formData, response);
    } catch (error) {
      setActionDisabled(false);
      if (error.response.data.status_type === "TOKEN_EXPIRED") {
        setErrorMessage(
            `Failed to create payment transaction: ${error.response.data.message}. You will be redirected to the sign-in page in 5 seconds.`
        );
        setTimeout(() => handleLogout(), 5000);
      } else {
        handleOtherErrors(error);
      }
      onNext(formData, error.response.data as PayByLinkResponse);
    }
  };

  const handleNext = (
    formData,
    transactionResponse: RFPaymentResponse | PayByLinkResponse
  ) => {
    onNext(formData, transactionResponse);
  };
  return (
    <Stack>
      <Grid sx={{ mt: 5 }}>{/* <h2>Step 2: Review</h2> */}</Grid>
      <Grid
        container
        columnSpacing={2}
        sx={{ padding: 2 }}
        flexDirection={isSmallerFormFactor ? 'column' : 'row'}
        justifyContent="center"
        alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
        <Grid item xs={isSmallerFormFactor ? false : false}>
          <Container
            maxWidth="sm"
            sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
            {errorMessage && (
              <Alert variant="outlined" severity="error">
                {errorMessage}
              </Alert>
            )}
          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        // sx={{ padding: 2 }}
        flexDirection={isSmallerFormFactor ? 'column' : 'row'}
        justifyContent="center"
        alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
        {/* {!paymentTransaction && <LinearProgress />} */}
        {formData.transaction_mode === 'pay-now' && paymentTransaction && (
          <>
            <Grid item xs={isSmallerFormFactor ? false : false}>
              <rainforest-payment
                ref={rainforestPayment}
                session-key={paymentTransaction.session.session_key}
                payin-config-id={
                  paymentTransaction.payment_transaction.rf_payin_config_id
                }
                allowed-methods="CARD, ACH"></rainforest-payment>
            </Grid>
          </>
        )}
        {paymentTransaction && formData.transaction_mode === 'pay-by-link' && (
          <TableContainer>
            <Table aria-label="transaction-table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6" align="center">
                      Transaction Review
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionTableRows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell
                      component="th"
                      scope="row"
                      size="small"
                      align="left">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid
        sx={{ mt: 2, mb: 2 }}
        container
        columnSpacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center">
        {paymentTransaction && formData.transaction_mode === 'pay-by-link' && (
          <Grid item xs={isSmallerFormFactor ? false : false}>
            <Button
              onClick={handleSendPayByLink}
              variant="contained"
              disabled={actionDisabled}
              color="primary">
              Send PayByLink
            </Button>
          </Grid>
        )}
        <Grid item xs={isSmallerFormFactor ? false : false}>
          <Button onClick={handlePrevious} variant="contained" disabled={actionDisabled} color="primary">
            Previous
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Review;
