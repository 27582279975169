import {
    Button, FormControl,
    FormControlLabel, FormHelperText,
    Grid, InputAdornment, InputLabel, OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    SelectChangeEvent
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormData} from './APTPaymentWizard';
import {getClientChannel} from '../../api/service'
import {useAuth} from '../../context';
import '../../App.css';


interface PatientDetailsProps {
    onNext: (data: any) => void;
    formData: FormData;
}

function PatientDetails<PatientDetailsProps>({onNext, formData}) {
    const theme = useTheme();
    const {auth} = useAuth();
    const [clientChannelList, setClientChannelList] =
        useState<any>(null);

    const [clientChannelError, setClientChannelError] = useState('');

    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));

    const [localFormData, setLocalFormData] = useState<FormData>({
        first_name: '',
        last_name: '',
        client_code: '',
        notes: '',
        email: '',
        phone_number: '',
        amount: '',
        transaction_mode: '',
        client_channel_id: '',

    });

    const [selectedOption, setSelectedOption] = useState<string>(clientChannelList && clientChannelList[0].client_channel_id);

    const [errors, setErrors] = useState<Partial<FormData>>({});


    useEffect(() => {
        const fetchClientChannel = async () => {
            try {
                const clientChannelData = await getClientChannel(auth.id);
                setClientChannelList(clientChannelData);

                if (clientChannelData.length === 1) {
                    setSelectedOption(clientChannelData[0].client_channel_id);
                }
            } catch (error) {
                setClientChannelError(
                    error?.response?.data.message
                        ? `Failed to fetch client channel ${error?.response?.data.message}`
                        : 'An unexpected error occurred'
                );
            }
        };

        fetchClientChannel();
    }, [auth.id]);


    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setSelectedOption(e.target.value);
    };

    function validatePhoneNumber(phoneNumber: string): boolean {
        // Regular expression to match a valid phone number
        const phoneRegex = /^\+?\d{0,3}?\-?\d{3}\-?\d{2,3}\-?\d{4}$/;
        return phoneRegex.test(phoneNumber);
    }

    function validateEmailAddress(email: string): boolean {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
        return emailRegex.test(email);
    }

    const validateForm = (): boolean => {
        let newErrors: Partial<FormData> = {};
        if (!localFormData.client_code.trim()) {
            newErrors.client_code = 'Client Code is required';
        }
        if (!localFormData.first_name.trim()) {
            newErrors.first_name = 'First name is required';
        } else if (localFormData.first_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.first_name.trim())) {
            newErrors.first_name = 'Special characters are not allowed in First Name';
        } else if (localFormData.first_name.trim().length > 40) {
            newErrors.first_name = 'First name can not be longer than 40 characters';
        }
        if (!localFormData.last_name.trim()) {
            newErrors.last_name = 'Last name is required';
        } else if (localFormData.last_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.last_name.trim())) {
            newErrors.last_name = 'Special characters are not allowed in Last Name';
        } else if (localFormData.last_name.trim().length > 40) {
            newErrors.last_name = 'Last name can not be longer than 40 characters';
        }
        if (!localFormData.phone_number.trim()) {
            newErrors.phone_number = 'Phone number is required';
        }
        if (
            localFormData.phone_number?.trim() &&
            !validatePhoneNumber(localFormData.phone_number?.trim())
        ) {
            newErrors.phone_number = 'Please provide a valid phone number';
        }
        if (!localFormData.email.trim()) {
            newErrors.email = 'Email is required';
        }
        if (!localFormData.email?.trim()
            || localFormData.email?.trim().length > 100) {
            newErrors.email = 'Email field can not be empty or longer than 100 characters';
        }
        if (
            localFormData.email?.trim() &&
            !validateEmailAddress(localFormData.email?.trim())
        ) {
            newErrors.email = 'Please provide a valid email address';
        }
        if (!localFormData.notes.trim()) {
            newErrors.notes = 'Note is required';
        } else if (localFormData.notes.trim().length > 64) {
            newErrors.notes = 'Notes cannot be longer than 64 characters';
        }
        if (
            isNaN(parseFloat(localFormData.amount)) ||
            parseFloat(localFormData.amount) < 0.01 ||
            parseFloat(localFormData.amount) > 50000
        ) {
            newErrors.amount =
                'Amount must be a numeric value between 0.01 and 50000';
        }

        if (
            localFormData.transaction_mode !== 'pay-now' &&
            localFormData.transaction_mode !== 'pay-by-link'
        ) {
            newErrors.transaction_mode =
                'Transaction mode must be either "pay-now" or "pay-by-link"';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        switch (name) {
            case 'amount':
                // Remove any non-numeric characters except the first decimal point and ensure at most two decimal places
                formattedValue = formattedValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except '.'
                formattedValue = formattedValue.replace(/^(\d*\.\d*).*$/, '$1'); // Ensure only one '.' in the value
                formattedValue = formattedValue.replace(/(\.\d\d).+/, '$1'); // Ensure at most two decimal places

                // Parse the formatted value as a float
                const floatValue = parseFloat(formattedValue);

                // Check if it's a valid number and within the specified range
                if (isNaN(floatValue) || floatValue < 0.01 || floatValue > 50000.00) {
                    error = 'Amount must be a value between 0.01 and 50000.00';
                } else {
                    // Format the value with commas for thousands separators
                    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '');
                }

                // Check if the formatted value is empty or '0.00'
                if (formattedValue === '' || formattedValue === '0.00') {
                    error = 'Amount is required';
                }
                break;
            case 'phone_number':
                formattedValue = formattedValue.replace(/[^\d]/g, '');
                if (formattedValue.length > 3) {
                    formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
                }
                if (formattedValue.length > 7) {
                    formattedValue = `${formattedValue.slice(0, 7)}-${formattedValue.slice(7)}`;
                }
                formattedValue = formattedValue.slice(0, 15);
                break;
            case 'client_code':
                if (/[^a-zA-Z0-9\s.]/.test(formattedValue)) {
                    console.log("error")
                    error = 'Special characters are not allowed';
                } else if (formattedValue.length < 1) {
                    error = 'Field can not be empty';
                } else if (formattedValue.length > 50) {
                    error = 'Maximum 50 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 50);
                break;
            case 'first_name':
            case 'last_name':
                if (/[^a-zA-Z\s]/.test(formattedValue)) {
                    error = 'Special characters are not allowed';
                } else if (formattedValue.length < 1) {
                    error = 'Field can not be empty';
                } else if (formattedValue.length > 40) {
                    error = 'Maximum 40 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 40);
                break;
            case 'email':
                if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formattedValue)) {
                    error = 'Please provide a valid email address';
                } else if (formattedValue.length > 100) {
                    error = 'Email must be less than 100 characters';
                } else {
                    const [local, domain] = formattedValue.split('@');
                    if (local.length > 64 || (local + '@' + domain).length > 100) {
                        error = 'Email is too long';
                    }
                }
                formattedValue = formattedValue.slice(0, 100);

                break;
            case 'notes':
                if (/[^a-zA-Z0-9\s.\-.]/.test(formattedValue)) {
                    error = 'Special characters other than . - are not allowed';
                } else if (formattedValue.length > 64) {
                    error = 'Maximum 64 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 64);

                break;
            default:
                break;
        }

        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalFormData({
            ...localFormData,
            transaction_mode: e.target.value,
        });
    };

    const handleNext = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            const amountInCents = parseFloat(localFormData.amount) * 100;
            const formDataInCents: FormData = {
                ...localFormData,
                amount: parseInt(amountInCents.toFixed(0)).toString(),
                client_channel_id: selectedOption
            };

            onNext(formDataInCents);
        }
    };
    const handlePaste = (e) => {
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        const pastedText = e.clipboardData.getData('text/plain');
        const containsNonNumeric = pastedText.split('').some((char) => isNaN(parseInt(char)));

        switch (name) {
            case 'client_code':
                if (/[^a-zA-Z0-9\s.]/.test(pastedText)) {
                    e.preventDefault();
                    error = 'Special characters other than periods are not allowed (Copied text includes special character)';
                }
                break;
            case 'first_name':
            case 'last_name':
                if (/[^a-zA-Z\s]/.test(pastedText)) {
                    e.preventDefault();
                    error = 'Special characters are not allowed (Copied text includes special character)';
                }
                break;
            case 'email':
                // Restrict entry to letters, numbers, periods, and @ only
                if (/[^a-zA-Z0-9.@]/.test(pastedText)) {
                    e.preventDefault();
                    error = 'Special characters are not allowed other than . @ (Copied text includes special character)';
                }
                break;
            case 'phone_number':
                // Allow only numeric input
                if (containsNonNumeric) {
                    e.preventDefault();
                    error = 'Only Numericals are allowed (Copied text includes non-numeric character)';
                }
                break;
            case 'notes':
                if (/[^a-zA-Z0-9.\-]/.test(pastedText)) {
                    e.preventDefault();
                    error = 'Special characters other than periods are not allowed (Copied text includes special character)';
                }
                break;
            default:
                break;
        }

        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };
    const handleKeyDown = (e) => {
        const {name, value} = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        switch (name) {
            case 'client_code':
                if (/[^a-zA-Z0-9\s.]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'first_name':
            case 'last_name':
                if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'email':
                // Restrict entry to letters, numbers, periods, and @ only
                if (/[^a-zA-Z0-9.@]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'phone_number':
                // Allow only numeric input
                if (!/\d/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'notes':
                if (/[^a-zA-Z0-9.\-]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            default:
                break;
        }

        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));
    };
    useEffect(() => {
        if (formData) {
            setLocalFormData(formData);
        }
    }, [formData]);

    return (
        <Stack>
            <Grid
                container
                columnSpacing={2}
                flexDirection={isSmallerFormFactor ? 'column' : 'row'}
                justifyContent="center"
                alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
                <Grid item xs={isSmallerFormFactor ? 12 : 6}>
                    <FormControl fullWidth variant="outlined" size="small" margin="normal">
                        {clientChannelList && clientChannelList.length === 1 ? " " : (
                            <InputLabel id="select-label">Client Channel</InputLabel>
                        )}
                        <Select
                            labelId="select-label"
                            id="select"
                            required
                            fullWidth
                            value={selectedOption}
                            onChange={handleSelectChange}
                            label="Client Channel"
                            disabled={clientChannelList && clientChannelList.length === 1}

                        >
                            {clientChannelList && clientChannelList.map((item) => (
                                <MenuItem key={item.client_channel_id}
                                          value={item.client_channel_id}>{item.client_channel_name}</MenuItem>
                            ))}
                        </Select>
                        {clientChannelError &&
                            <FormHelperText sx={{color: '#f44336'}}>{clientChannelError}</FormHelperText>}
                    </FormControl>
                    <TextField
                        name="client_code"
                        label="Client Code"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        size="small"
                        value={localFormData.client_code}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        error={!!errors.client_code}
                        helperText={errors.client_code}
                    />
                    <TextField
                        name="first_name"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        size="small"
                        value={localFormData.first_name}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        error={!!errors.first_name}
                        helperText={errors.first_name}
                    />
                    <TextField
                        name="last_name"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        size="small"
                        value={localFormData.last_name}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        error={!!errors.last_name}
                        helperText={errors.last_name}
                    />

                    <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        required
                        value={localFormData.email}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onPaste={(e) => handlePaste(e)}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        name="phone_number"
                        label="Phone Number"
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        size="small"
                        value={localFormData.phone_number}
                        onChange={handleChange}
                        onPaste={(e) => handlePaste(e)}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number}
                    />
                    <FormControl fullWidth sx={{mt: 2}}>
                        <InputLabel htmlFor="amount" shrink={true}
                                    sx={{zIndex: 2, backgroundColor: '#F5F5F5', pl: 1, pr: 1}}>Amount * </InputLabel>
                        <OutlinedInput
                            id="amount"
                            name="amount"
                            size="small"
                            required
                            value={localFormData.amount}
                            error={!!errors.amount}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">$</InputAdornment>
                            }
                        />
                        <FormHelperText error={!!errors.amount}>
                            {errors.amount}
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        name="notes"
                        label="Notes"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        size="small"
                        value={localFormData.notes}
                        onChange={handleChange}
                        onPaste={(e) => handlePaste(e)}
                        // onKeyDown={(e) => handleKeyDown(e)}
                        error={!!errors.notes}
                        helperText={errors.notes}
                    />
                    <Grid container spacing={2} sx={{p: 2, pt: 2}}>
                        <Stack>
                            <Typography
                                variant="body2"
                                display="block"
                                gutterBottom
                                align="left">
                                Transaction Mode
                            </Typography>
                            <RadioGroup
                                sx={{pl: 2}}
                                row
                                defaultValue="pay-now"
                                aria-labelledby="transaction_mode"
                                aria-label="Transaction method"
                                name="transaction_mode"
                                value={localFormData.transaction_mode}
                                onChange={handleModeChange}>
                                <FormControlLabel
                                    value="pay-now"
                                    control={<Radio/>}
                                    label="PayNow"
                                />
                                <FormControlLabel
                                    value="pay-by-link"
                                    control={<Radio/>}
                                    label="PayByLink"
                                />
                            </RadioGroup>
                            {errors.transaction_mode && (
                                <span style={{color: '#d32f2f', fontSize: '0.75rem'}}>{errors.transaction_mode}</span>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{mt: 1}}
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Button variant="contained" color="primary" onClick={handleNext}>
                    Next
                </Button>
            </Grid>
        </Stack>
    );
}

export default PatientDetails;
