import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Toolbar,
  useMediaQuery,
  useTheme,
  CircularProgress,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Modal,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { THEME_KRI } from "../../assets/kri/theme";
import AdminNavigations from "../../components/navigations/AdminNavigations";
import SuccessFailPieChart from "../../components/dashboard/pieChart";
import { useAuth } from "../../context";
import StickyFooter from "../../components/StickyFooter";
import {
  getDashboardExportData,
  getDashboardGraphData,
} from "../../api/service";

export default function DashboardPage() {
  const { auth } = useAuth();
  const theme = useTheme();
  const [selectedPeriod, setSelectedPeriod] = useState<number>(30);
  const [clientChannelError, setClientChannelError] = useState("");
  const [totalAttempt, setTotalAttempt] = useState<number | null>(null);
  const [totalSuccessfulAttempt, setTotalSuccessfulAttempt] = useState<
    number | null
  >(null);
  const [totalFailedAttempt, setTotalFailedAttempt] = useState<number | null>(
    null
  );
  const [totalTransaction, setTotalTransaction] = useState<number | null>(null);
  const [totalSuccessfulTransaction, setTotalSuccessfulTransaction] = useState<
    number | null
  >(null);
  const [totalFailedTransaction, setTotalFailedTransaction] = useState<
    number | null
  >(null);
  const [loading, setLoading] = useState(true); // Set to true initially to show spinner on load
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState("");
  const [noDataOpen, setNoDataOpen] = useState(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "5px",
    width: 400,
    display: "flex",
    flexDirection: "column",
    gap: "0.4rem",
  };

  useEffect(() => {
    const fetchDashboardGraph = async (day: number) => {
      setLoading(true); // Show spinner when fetching data
      try {
        const dashboardGraph = await getDashboardGraphData(day);
        setTotalAttempt(dashboardGraph?.attempts?.total_attempt ?? 0);
        setTotalSuccessfulAttempt(
          dashboardGraph?.attempts?.total_success_attempt ?? 0
        );
        setTotalFailedAttempt(
          dashboardGraph?.attempts?.total_unsuccessfull_attempt ?? 0
        );
        setTotalTransaction(
          (dashboardGraph?.transaction?.total_transaction ?? 0) / 100
        );
        setTotalSuccessfulTransaction(
          (dashboardGraph?.transaction?.total_success_transaction ?? 0) / 100
        );
        setTotalFailedTransaction(
          (dashboardGraph?.transaction?.total_unsuccessfull_transaction ?? 0) /
            100
        );
      } catch (error) {
        setClientChannelError(
          error?.response?.data?.message
            ? `Failed to fetch graph data: ${error.response.data.message}`
            : "An unexpected error occurred"
        );
      } finally {
        setLoading(false); // Hide spinner after data is fetched or error occurs
      }
    };

    fetchDashboardGraph(selectedPeriod); // Fetch data whenever the period changes
  }, [auth.id]);

  const fetchDashboardGraphChange = async (day: number) => {
    setExportLoading(true); // Show spinner when fetching data
    try {
      const dashboardGraph = await getDashboardGraphData(day);
      setTotalAttempt(dashboardGraph?.attempts?.total_attempt ?? 0);
      setTotalSuccessfulAttempt(
        dashboardGraph?.attempts?.total_success_attempt ?? 0
      );
      setTotalFailedAttempt(
        dashboardGraph?.attempts?.total_unsuccessfull_attempt ?? 0
      );
      setTotalTransaction(
        (dashboardGraph?.transaction?.total_transaction ?? 0) / 100
      );
      setTotalSuccessfulTransaction(
        (dashboardGraph?.transaction?.total_success_transaction ?? 0) / 100
      );
      setTotalFailedTransaction(
        (dashboardGraph?.transaction?.total_unsuccessfull_transaction ?? 0) /
          100
      );
    } catch (error) {
      setClientChannelError(
        error?.response?.data?.message
          ? `Failed to fetch graph data: ${error.response.data.message}`
          : "An unexpected error occurred"
      );
    } finally {
      setExportLoading(false); // Hide spinner after data is fetched or error occurs
    }
  };
  const handlePeriodChange = (event: SelectChangeEvent<number>) => {
    setSelectedPeriod(event.target.value as number); // Update selected period
    fetchDashboardGraphChange(event.target.value as number);
  };
  const handleNoDataClose = () => setNoDataOpen(false);
  const handleExport = async (day: number) => {
    if (error) return;
    setExportLoading(true); // Show loading overlay
    try {
      const dashboardExport = await getDashboardExportData(day);
      if (!dashboardExport.success) {
        setNoDataOpen(true);
      }
      // Handle export data (e.g., download or display a success message)
    } catch (e) {
      setError("Export failed");
      setNoDataOpen(false);
      console.error("Export failed:", e);
    } finally {
      setExportLoading(false); // Hide loading overlay
    }
  };

  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <AdminNavigations />
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: "hidden",
            padding: 2,
          }}
        >
          <Toolbar />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress size={100} />
            </Box>
          ) : (
            <Stack spacing={3}>
              <Typography variant="h4" textAlign={"left"} sx={{paddingLeft: "11rem", marginTop: "1rem"}}>
                Dashboard
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", marginTop: "0px !important", paddingLeft: "11rem" }}>
                <Typography variant="body2" textAlign={"left"}>
                  Status of transactions in last{" "}
                </Typography>
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 60, margin: "0px 8px", textAlign: "center" }}
                >
                  <Select
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    disableUnderline
                    sx={{ fontSize: "inherit" }}
                  >
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body2" textAlign={"left"}>
                  {" "}
                  Days
                </Typography>
                <Typography
                  textAlign={"left"}
                  variant="body2"
                  sx={{ fontSize: "1rem", margin: "0 10px !important" }}
                >
                  <Link
                    onClick={() => handleExport(selectedPeriod)}
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    {" "}
                    Export Report{" "}
                  </Link>
                </Typography>
              </Box>

              <Grid
                container
                spacing={2}
                width="80%"
                sx={{ margin: "0 auto !important" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                >
                  <SuccessFailPieChart
                    successCount={totalSuccessfulAttempt}
                    failedCount={totalFailedAttempt}
                    successColor={"#003f5c"}
                    failedColor={"#ed3c16"}
                    SuccessLabel={"Successful Attempts"}
                    FailedLabel={"Unsuccessful Attempts"}
                    ChartLabel={"Attempts"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent="center"
                >
                  <SuccessFailPieChart
                    successCount={totalSuccessfulTransaction}
                    failedCount={totalFailedTransaction}
                    successColor={"#003f5c"}
                    failedColor={"#ffa600"}
                    SuccessLabel={"Successful Transaction Amount"}
                    FailedLabel={"Unsuccessful Transaction Amount"}
                    ChartLabel={"Amount ($)"}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 8, margin: "45px auto  !important" }}
                width="60%"
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                      <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white",fontSize: "1.2rem" }}
                        >
                          Summary</TableCell>
                        <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white" }}
                        >
                          Attempt
                        </TableCell>
                        <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white" }}
                        >
                          Successful
                        </TableCell>
                        <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white" }}
                        >
                          Unsuccessful
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white" }}
                        >
                          Attempts
                        </TableCell>
                        <TableCell>{totalAttempt}</TableCell>
                        <TableCell>{totalSuccessfulAttempt}</TableCell>
                        <TableCell>{totalFailedAttempt}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: "#9b9a9a", color: "white" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell>
                          {(totalTransaction ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>
                          {(totalSuccessfulTransaction ?? 0).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell>
                          {(totalFailedTransaction ?? 0).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Stack>
          )}
        </Box>

        {exportLoading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress size={100} />
          </Box>
        )}

        <Paper
          sx={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            backgroundColor: "#0C415B",
            pt: 2,
            pb: 1,
            "@media (max-width: 600px)": {
              height: "50px",
            },
          }}
          component="footer"
          square
          variant="outlined"
        >
          <StickyFooter />
        </Paper>
      </Box>
      <Modal open={noDataOpen} onClose={handleNoDataClose}>
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            No data found with the given criteria.
          </Typography>
          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            <Button onClick={handleNoDataClose} variant="contained">
              Close
            </Button>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
