import {
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AdminNavigations from '../components/navigations/AdminNavigations';
import StickyFooter from '../components/StickyFooter';
import MenuIcon from '@mui/icons-material/Menu';
import PayByLink from '../components/pay-by-link/PayByLink';
import { THEME_KRI } from '../assets/kri/theme';

export default function AdminHelpPage() {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <AdminNavigations />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Stack spacing={2}>
              <Grid
                container
                spacing={1}
                justifyContent={'flex-start'}
                direction="column">
                <Grid item sx={{ mt: 2, ml: 1 }}>
                  <Typography variant="h4" textAlign={'left'}>
                    Help
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 3, ml: 1 }}>
                  <Typography textAlign={'left'} variant="h5">
                    Getting Started
                  </Typography>
                  <Typography
                    textAlign={'left'}
                    variant="body2"
                    sx={{ p: 0.5 }}>
                    Enter "
                    <Link
                      color="inherit"
                      href="https://platform.aptfinancial.co">
                      https://platform.aptfinancial.co
                    </Link>
                    " in a web browser to navigate to the Client Payment Portal powered by Apt Financial. Add a
                    bookmark to make it easy to access.{' '}
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    variant="body2"
                    sx={{ p: 0.5,textAlign: 'justify' }}>
                    Login with the User Name and Password sent to you by your
                    admin. If you forget your User Name or Password please reach
                    out to your manager or{' '}
                    <Link color="inherit" href="mailto:support@aptfinancial.co">
                      support@aptfinancial.co
                    </Link>
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    variant="body2"
                    sx={{ p: 0.5,textAlign: 'justify' }}>
                    The Menu can be accessed via the icon at the top right of
                    the page:
                    <MenuIcon sx={{ pt: 1 }} />
                  </Typography>
                </Grid>

                <Grid item sx={{ mt: 3, ml: 1 }}>
                  <Typography textAlign={'left'} variant="h5">
                    Payments
                  </Typography>
                  <Typography
                    textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5 }}>
                    <strong>Create a Patient Payment</strong>
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5,textAlign: 'justify' }}>
                    Click the "Payments" button or select Payments from the
                    menu.
                  </Typography>
                  <Typography 
                  // textAlign={'left'} 
                    variant="body2">
                    <ol>
                      <li>
                        <Typography textAlign={'justify'} variant="body2">
                          Enter the "Client Code" for the patient from your CRM
                          system. Client Code is a required field. If you do not
                          have access to the Client Code, enter a temporary code
                          using the patient’s first and last name. Example: for
                          Jeffry Wilson enter "jeffrywilson" in the Client Code
                          field.
                        </Typography>
                        <Typography
                          // textAlign={'left'}k
                          variant="body2"
                          sx={{ pt: 0.25,textAlign: 'justify' }}>
                          Enter the patient’s First Name, Last Name, Email
                          (required), Phone Number and Amount of payment
                          (required). Optionally add a short note about the
                          payment.
                        </Typography>
                        <Typography
                          // textAlign={'left'}
                          variant="body2"
                          sx={{ pt: 0.25,textAlign: 'justify' }}>
                          Select "PayNow" to complete a payment on behalf of a
                          patient. OR Select "PayByLink" to send a payment link
                          to be completed by the patient.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          // textAlign={'left'}
                          variant="body2"
                          sx={{ pt: 1.5,textAlign: 'justify' }}>
                          For "PayNow" payments click "Next" to enter Credit
                          Card or Bank Transfer details. Review the payment
                          details and click the "Pay" button to complete the
                          transaction. The client will receive a receipt via
                          email and the admissions user will receive a
                          confirmation message.
                        </Typography>
                        <Typography
                          // textAlign={'left'}
                          variant="body2"
                          sx={{ pt: 0.25 ,textAlign: 'justify' }}>
                          For "PayByLink" payments click "Next" to review the
                          payment details. If changes are required click the
                          "Previous" button to make changes and click "Next" to
                          proceed. Click the "Send PayByLink" button. An email
                          will be sent to the client with a link to complete the
                          payment in our secure payments portal by credit card
                          or bank transfer. Upon completing the payment, the
                          client will receive a receipt via email and the
                          admissions user will receive a confirmation message.
                        </Typography>
                      </li>
                    </ol>
                  </Typography>
                </Grid>

                <Grid item sx={{ mt: 3, ml: 1 }}>
                  <Typography textAlign={'left'} variant="h5">
                    Reports
                  </Typography>
                  <Typography
                    textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5 }}>
                    <strong>View Payments Report</strong>
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5,textAlign: 'justify' }}>
                    To view the Payment Report click the "Reports" button or
                    select "Reports" from the menu. The Payment Report lists
                    recent transactions with payment details including Client
                    Code, Billing Contact, Amount, Status, Method and Mode of
                    Payment and Date and Time Created. The Payments Report is
                    sorted by Date and Time Created by default.
                  </Typography>

                  <Typography textAlign={'left'} variant="body2" sx={{ pt: 1}}>
                    <strong>Cancel or Refund a Payment</strong>
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    textAlign={'justify'}
                    variant="body2"
                    sx={{ pt: 0.5 }}>
                    To cancel or refund a payment, select a payment record to
                    view payment details, then click the "Refund" button. Select
                    a "Reason" from the drop down options and click the "Confirm
                    refund" button. If a payment has not been processed it will
                    be canceled. If a payment has completed processing a refund
                    will be issued. To close the Payment Detail view, click "x"
                    next to the "Refund" button.
                  </Typography>

                  <Typography textAlign={'left'} variant="body2" sx={{ pt: 1 }}>
                    <strong>Sorting the Payments Report</strong>
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    textAlign={'justify'}                    
                    variant="body2"
                    sx={{ pt: 0.5 }}>
                    To sort the Payments Report click on a column header. The
                    report will be sorted by the column selected. An up or down
                    arrow will indicate ascending or descending sort order.
                    Click again on a column to change sort order. The default
                    sort order is by date Created.
                  </Typography>
                </Grid>

                <Grid item sx={{ mt: 3, ml: 1 }}>
                  <Typography textAlign={'left'} variant="h5">
                    Support
                  </Typography>
                  <Typography
                    textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5}}>
                    For questions or assistance with the Apt Financial Payment
                    Portal, please contact:{' '}
                    <Link color="inherit" href="mailto:support@aptfinancial.co">
                      support@aptfinancial.co
                    </Link>
                  </Typography>
                </Grid>

                <Grid item sx={{ mt: 3, ml: 1 }}>
                  <Typography textAlign={'left'} variant="h5">
                    Feedback & Suggestions
                  </Typography>
                  <Typography
                    // textAlign={'left'}
                    variant="body2"
                    sx={{ pt: 0.5,textAlign: 'justify' }}>
                    We value your feedback and continuously strive to improve
                    our services. If you have suggestions or ideas on how we can
                    better serve your needs, please don't hesitate to reach out:{' '}
                    <Link color="inherit" href="mailto:support@aptfinancial.co">
                      support@aptfinancial.co
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Container>
        </Box>
        <Paper
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#0C415B',
            pt: 2,
            pb: 1,
            '@media (max-width: 600px)': {
              height: '50px',
            },
          }}
          component="footer"
          square
          variant="outlined">
          <StickyFooter />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
