import {Button, Paper, Stack, useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import AdminNavigations from '../../components/navigations/AdminNavigations';
import StickyFooter from '../../components/StickyFooter';
import {useAuth} from '../../context';
import {THEME_KRI} from '../../assets/kri/theme';
import {useEffect, useState} from "react";
import CustomerList from './CustomerList';


export default function CustomerManagement() {
    const {auth} = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
  

    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                <CssBaseline/>
                <AdminNavigations/>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{pt: 4, pb: 4}}>
                        <Stack spacing={2}>
                            
                            <Grid container spacing={1} justifyContent={'flex-end'}>
                                    {auth && auth.roles.some(role => role.includes("5a86b9bf-58f0-4f7c-bcc2-5e99f63ed34f"))  &&
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/admin/create/customer')}
                                            sx={{minWidth: '165px'}}>
                                            CREATE CUSTOMER
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </Stack>
                       &nbsp;
                    <CustomerList/>

                    </Container>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter/>
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
