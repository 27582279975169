import {
    Alert,
    FormControl, FormHelperText,
    Grid,
    Paper, Stack,
    useMediaQuery,
    useTheme,
    Input
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { createCustomer } from '../../api/service';
import StickyFooter from '../../components/StickyFooter';
import { useAuth } from '../../context';
import { THEME_KRI } from '../../assets/kri/theme';
import AdminNavigations from "../../components/navigations/AdminNavigations";
import { useNavigate } from 'react-router-dom';


export interface CreateCustomerFormData {
    customer_name?: string;
    logo?: any;
    facility_name?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    user_name?: string;
    merchant_id?: string;
    platform_id?: string;
}


export default function CreateCustomerPage() {
    const { auth, setAuth } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errors, setErrors] = useState<CreateCustomerFormData>({});
    const [actionDisabled, setActionDisabled] = useState(false);

    const [localFormData, setLocalFormData] = useState<CreateCustomerFormData>({
        customer_name: "",
        logo: null,
        facility_name: "",
        email: "",
        first_name: "",
        last_name: "",
        user_name: "",
        merchant_id: "",
        platform_id: ""
    });


    function validateEmailAddress(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
        return emailRegex.test(email);
    }

    const validateForm = () => {
        let newErrors: Partial<any> = {};

        if (!localFormData.logo) {
            newErrors.logo = "Please upload a logo";
        } else {
            const fileType = localFormData.logo.type;
            if (!fileType.startsWith('image/')) {
                newErrors.logo = 'Only image is allowed, please select an image';
            }
        }

        if (!localFormData.customer_name.trim() || localFormData.customer_name.trim().length > 40) {
            newErrors.customer_name = 'Customer name field can not be empty or longer than 40 characters';
        } else if (localFormData.customer_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.customer_name.trim())) {
            newErrors.customer_name = 'Special characters are not allowed in Customer Name';
        }
        if (!localFormData.facility_name.trim() || localFormData.facility_name.trim().length > 40) {
            newErrors.facility_name = 'First name field can not be empty or longer than 40 characters';
        } else if (localFormData.facility_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.facility_name.trim())) {
            newErrors.facility_name = 'Special characters are not allowed in First Name';
        }

        if (!localFormData.first_name.trim() || localFormData.first_name.trim().length > 40) {
            newErrors.first_name = 'First name field can not be empty or longer than 40 characters';
        } else if (localFormData.first_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.first_name.trim())) {
            newErrors.first_name = 'Special characters are not allowed in First Name';
        }

        if (!localFormData.last_name.trim() || localFormData.last_name.trim().length > 40) {
            newErrors.last_name = 'Last name field can not be empty or longer than 40 characters';
        } else if (localFormData.last_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.last_name.trim())) {
            newErrors.last_name = 'Special characters are not allowed in Last Name';
        }
        if (!localFormData.user_name.trim() || localFormData.user_name.trim().length > 40) {
            newErrors.user_name = 'User name field can not be empty or longer than 40 characters';
        } else if (localFormData.user_name.trim() && /[^a-zA-Z0-9\s]/.test(localFormData.user_name.trim())) {
            newErrors.user_name = 'Special characters are not allowed in User Name';
        }
        if (!localFormData.merchant_id.trim() || localFormData.merchant_id.trim().length > 40) {
            newErrors.merchant_id = 'Merchant Id field can not be empty or longer than 40 characters';
        }
        if (!localFormData.platform_id.trim() || localFormData.platform_id.trim().length > 40) {
            newErrors.platform_id = 'Platform Id field can not be empty or longer than 40 characters';
        }

        if (!localFormData.email?.trim()
            || localFormData.email?.trim().length > 100) {
            newErrors.email = 'Email field can not be empty or longer than 100 characters';
        }

        if (!validateEmailAddress(localFormData.email?.trim())) {
            newErrors.email = 'Please provide a valid email address';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        switch (name) {
            case 'customer_name':
            case 'facility_name':
            case 'user_name':
            case 'first_name':
            case 'last_name':
                if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'email':
                if (/[^a-zA-Z0-9.@]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            default:
                break;
        }

        setLocalFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));
    };

    const handleChange = (e) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value.trim();
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));


        switch (name) {
            case 'customer_name':
            case 'facility_name':
            case 'user_name':
            case 'first_name':
            case 'last_name':
                if (/[^a-zA-Z0-9\s]/.test(formattedValue)) {
                    console.log("error")
                    error = 'Special characters are not allowed';
                } else if (formattedValue.length < 1) {
                    error = 'Field can not be empty';
                } else if (formattedValue.length > 40) {
                    error = 'Maximum 40 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 40);
                break;
            case 'merchant_id':
            case 'platform_id':
                if (formattedValue.length < 1) {
                    error = 'Field can not be empty';
                } else if (formattedValue.length > 40) {
                    error = 'Maximum 40 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 40);
                break;
            case 'logo':
                if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const fileType = file.type;
                    if (!fileType.startsWith('image/')) {
                        error = 'Only image is allowed, please select an image';
                    }
                }
                break;
            case 'email':
                if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formattedValue)) {
                    error = 'Please provide a valid email address';
                } else if (formattedValue.length > 100) {
                    error = 'Email must be less than 100 characters';
                } else {
                    const [local, domain] = formattedValue.split('@');
                    if (local.length > 64 || (local + '@' + domain).length > 100) {
                        error = 'Email is too long';
                    }
                }
                formattedValue = formattedValue.slice(0, 100);

                break;
            default:
                break;
        }
        if (name === "logo" && e.target.files.length > 0) {
            const uploadedFile = e.target.files[0];
            setLocalFormData((prevData) => ({
                ...prevData,
                [name]: uploadedFile,
            }));
        } else {
            setLocalFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));

    };


    const handleOtherErrors = (error) => {
        let errorMessage = 'Failed to create customer. ';
        if (error.response && error.response.data) {
            errorMessage += `Error: ${error.response.data.message}. `;
        } else if (error) {
            errorMessage += `Error: ${error}. `;
        } else {
            errorMessage += 'Please try again. ';
        }
        errorMessage += 'Contact support if the problem persists.'
        setErrorMessage(errorMessage);
        setActionDisabled(false);
    };

    const handleLogout = () => {
        setAuth(null);
        localStorage.removeItem('auth')
        window.location.href = '/login';
    };

    const transactionErrorHandler = (error) => {
        setActionDisabled(false);
        if (error.response && error.response.data) {
            if (error.response.data.status_type === 'TOKEN_EXPIRED') {
                setErrorMessage(
                    `Failed to update the password: ${error.response.data.message}. 
                    You will be redirected to the sign-in page in 5 seconds.`
                );
                setTimeout(() => handleLogout(), 5000);
            } else {
                handleOtherErrors(error);
            }
            return;
        }
        handleOtherErrors(error);
    }

    const createCustomerAsync = async () => {
        validateForm();
        const formData = new FormData();
        formData.append("logo_file", localFormData.logo);
        formData.append("client_name", localFormData.customer_name);
        formData.append("channel_name", localFormData.facility_name);
        formData.append("email", localFormData.email);
        formData.append("firstname", localFormData.first_name);
        formData.append("lastname", localFormData.last_name);
        formData.append("username", localFormData.user_name);
        formData.append("rf_merchant_id", localFormData.merchant_id);
        formData.append("rf_platform_id", localFormData.platform_id);
        await createCustomer(auth, formData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        setActionDisabled(true);
        if (validateForm()) {
            createCustomerAsync().then(() => {
                setSuccessMessage("Successfully created the customer and sent welcome email");
                setLocalFormData({
                    customer_name: "",
                    logo: '',
                    facility_name: "",
                    email: "",
                    first_name: "",
                    last_name: "",
                    user_name: "",
                    merchant_id: "",
                    platform_id: ""
                });
                const fileInput = document.getElementById("file-upload") as HTMLInputElement;
                if (fileInput) {
                    fileInput.value = "";
                }

                setActionDisabled(false);

            }).catch((error) => transactionErrorHandler(error));
        }
        else {
            setActionDisabled(false);
        }
    };

    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Toolbar />

                    <Stack>
                        <Grid
                            container
                            columnSpacing={2}
                            justifyContent="center"
                        >
                            <Grid item sx={{ mt: 2, ml: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography variant="h4" textAlign={'left'}>
                                    Create Customer
                                </Typography>
                            </Grid>
                            <Grid item sx={{ ml: 1, mb: 2 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography textAlign={'left'} variant="body2">
                                    Please complete form below to create new customer
                                </Typography>
                            </Grid>
                            <Grid item sx={{ m: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                {errorMessage && (
                                    <Alert variant="outlined" severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                                {successMessage && (
                                    <Alert variant="outlined" severity="success">
                                        {successMessage}
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item
                                component="form"
                                onSubmit={handleSubmit}
                                xs={isSmallerFormFactor ? 12 : 6}
                                sx={{ m: 1 }}>
                                <Typography
                                    variant="body2"
                                    display="block"
                                    align="left">
                                    Customer Facility
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="customername"
                                    name="customer_name"
                                    label="Customer Name"
                                    type="string"
                                    size="small"
                                    value={localFormData.customer_name}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.customer_name}
                                    helperText={errors.customer_name}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="facilityname"
                                    name="facility_name"
                                    label="Facility Name"
                                    size="small"
                                    value={localFormData.facility_name}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.facility_name}
                                    helperText={errors.facility_name}
                                />

                                <FormControl fullWidth margin="normal" error={!!errors.logo}>
                                    <label htmlFor="file-upload" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                                        <Button variant="contained" component="span" style={{ backgroundColor: 'lightgray', color: '#333', marginRight: '10px', height: '32px', fontSize: '14px' }}>
                                            <span>Upload Logo</span>
                                        </Button>
                                        <input
                                            id="file-upload"
                                            name="logo"
                                            type="file"
                                            style={{ display: 'none' }}
                                            {...{ accept: "image/*" }}
                                            onChange={handleChange}
                                        />
                                        {localFormData && <span style={{ marginLeft: '10px', fontSize: '14px' }}>{localFormData?.logo?.name?.slice(0, 30)}</span>}
                                    </label>
                                    <FormHelperText>{errors.logo}</FormHelperText>
                                </FormControl>

                                &nbsp;&nbsp;&nbsp;
                                <Typography
                                    variant="body2"
                                    display="block"
                                    align="left">
                                    Payment Detail Config
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="merchantid"
                                    name="merchant_id"
                                    label="Merchant Id"
                                    type="string"
                                    size="small"
                                    value={localFormData.merchant_id}
                                    onChange={handleChange}
                                    error={!!errors.merchant_id}
                                    helperText={errors.merchant_id}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="platformid"
                                    name="platform_id"
                                    label="Platform Id"
                                    type="string"
                                    size="small"
                                    value={localFormData.platform_id}
                                    onChange={handleChange}
                                    error={!!errors.platform_id}
                                    helperText={errors.platform_id}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Typography
                                    variant="body2"
                                    display="block"
                                    align="left">
                                    Operator Config
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    size="small"
                                    value={localFormData.email}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstname"
                                    name="first_name"
                                    label="First Name"
                                    type="string"
                                    size="small"
                                    value={localFormData.first_name}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.first_name}
                                    helperText={errors.first_name}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastname"
                                    name="last_name"
                                    label="Last Name"
                                    type="string"
                                    size="small"
                                    value={localFormData.last_name}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.last_name}
                                    helperText={errors.last_name}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    name="user_name"
                                    label="User Name"
                                    size="small"
                                    value={localFormData.user_name}
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}

                                    error={!!errors.user_name}
                                    helperText={errors.user_name}
                                />

                                <Grid container columnSpacing={2} justifyContent="center">
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/admin/customer/management')}
                                            sx={{ width: 'calc(50% - 8px)' }}>
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            onClick={handleSubmit}
                                            disabled={actionDisabled}
                                            sx={{ width: 'calc(50% - 8px)' }}>
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
