import { Alert, Button, Container, Grid, Stack, useMediaQuery, useTheme, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PayByLinkResponse, RFSession, SessionConfig } from '../../api/models';
import { RFPaymentResponse } from '../../api/rf_models';
import { fetchSessionConfig } from '../../api/service';
import { FormData } from './APTPaymentWizard';
import { useAuth } from "../../context";

interface ConfirmationProps {
  onFinish: () => void; // Add onFinish prop
  formData: FormData;
  transactionResponse: RFPaymentResponse | PayByLinkResponse;
}

const rfReceiptSmall = {
  textAlign: 'left',
  width: '90%',
};

const rfReceiptRegular = {
  textAlign: 'center',
};


const Confirmation: React.FC<ConfirmationProps> = ({
  onFinish,
  formData,
  transactionResponse,
}) => {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
  const { auth, setAuth } = useAuth();
  const [sessionConfig, setSessionConfig] = useState<SessionConfig>(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [payByLink, setPayByLink] = useState(null);

  const handleFinish = () => {
    onFinish();
  };

  // const aptServiceFetchSessionConfig = async () => {
  //   setSessionConfig(await fetchSessionConfig());
  // };

  const aptServiceFetchSessionConfig = async () => {
    // TODO - Need refactoring
    const clientChannel = auth?.roles[0].split("::")[0];
    setSessionConfig(await fetchSessionConfig(clientChannel));
  };

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem('auth')
    window.location.href = '/login';
  };

  useEffect(() => {
    setErrorMessages([]);
    // try {
    if (
      transactionResponse &&
      ('link' in transactionResponse || 'status' in transactionResponse)
    ) {
      const r = transactionResponse as any;
      if (r.status == 'error') {
        setErrorMessages((errorMessages) => [...errorMessages, r.message]);
      }
      if (r.status_type === "TOKEN_EXPIRED") {
        setErrorMessages([
          `Failed to create payment transaction: ${r.message}. You will be redirected to the sign-in page in 5 seconds.`
        ]);
      }
      if (r.link) {
        {
          setPayByLink(r.link);
        }
      }
    }

    // In case of pay-by-link this is not required
    if (
      transactionResponse &&
      'payin_id' in transactionResponse &&
      !sessionConfig &&
      !sessionConfig?.session
    ) {
      aptServiceFetchSessionConfig().catch((error) => {
        setErrorMessages((errorMessages) => [
          ...errorMessages,
          'Failed to establish session with payment gateway. You will not need to re-initiate transaction, please reach support',
        ]);
        setTimeout(() => handleLogout(), 5000);
      });
    }
  }, [formData, transactionResponse, sessionConfig, payByLink]);

  return (
    <Stack>
      <Grid sx={{ mt: 5 }}>{/* <h2>Step 3: Confirmation</h2> */}</Grid>
      <Grid
        container
        columnSpacing={2}
        sx={{ padding: 2 }}
        flexDirection={isSmallerFormFactor ? 'column' : 'row'}
        justifyContent="center"
        alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
        <Grid item xs={isSmallerFormFactor ? false : false}>
          <Container
            maxWidth="sm"
            sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
            {errorMessages &&
              errorMessages.length > 0 &&
              errorMessages.map((errorMessage, index) => (
                <Alert key={index} variant="outlined" severity="error">
                  {errorMessage}
                </Alert>
              ))}
          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        sx={{ padding: 2 }}
        flexDirection={isSmallerFormFactor ? 'column' : 'row'}
        justifyContent="center"
        alignItems={isSmallerFormFactor ? 'center' : 'flex-start'}>
        {transactionResponse &&
          'payin_id' in transactionResponse &&
          sessionConfig &&
          sessionConfig.session && (
            <Grid item xs={isSmallerFormFactor ? false : false}>
              <Container
                maxWidth="sm"
                sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
                <Alert variant="outlined" severity="success">
                  The payment of $
                  {parseInt(formData?.amount)
                    ? (parseInt(formData.amount) / 100).toString()
                    : ''}{' '}
                  was successfully processed! An email with instructions to
                  access the receipt will be sent to the patient shortly, and
                  you'll receive a copy as well. Below is the transaction
                  receipt for your reference.
                </Alert>
              </Container>
              <Container
                maxWidth="sm"
                sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
                <rainforest-payin-receipt
                  session-key={sessionConfig.session.session_key}
                  payin-id={
                    transactionResponse.payin_id
                  }></rainforest-payin-receipt>
              </Container>
            </Grid>
          )}
        {('link' in transactionResponse || 'status' in transactionResponse) && (
          <>
            {payByLink && (
              <Grid item xs={isSmallerFormFactor ? 'auto' : 8}>
                <Alert variant="outlined" severity="success">
                  Successfully sent the link for payment to email address:{' '}
                  {formData.email}
                </Alert>
              </Grid>
            )}
            <Grid
              sx={{ mt: 5, mb: 5 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Button
                onClick={handleFinish}
                variant="contained"
                color="primary">
                Close
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default Confirmation;
