import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://aptfinancial.co/">
        AptFinancial.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function StickyFooter() {
  return (
    // <Box
    //   component="footer"
    //   sx={{
    //     // marginTop: 'calc(20% + 60px)',
    //     backgroundColor: '#0C415B',
    //     // pt: 2,
    //     // pb: 2,
    //     // flexGrow: 1,
    //     // justifyContent: 'center',
    //     // display: 'flex',
    //     // my: 1,
    //   }}>
    <Copyright />
    // </Box>
  );
}
