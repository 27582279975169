import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const SuccessFailPieChart = ({
  successCount,
  failedCount,
  successColor,
  failedColor,
  SuccessLabel,
  FailedLabel,
  ChartLabel,
}) => {
  const labeltext:string = ChartLabel.toLowerCase().includes("amount") ? "Transactions" : ChartLabel;
  const total:number = successCount + failedCount;
  const data = {
    labels: [SuccessLabel, FailedLabel],
    datasets: [
      {
        label: ChartLabel,
        data: [successCount, failedCount],
        backgroundColor: [successColor, failedColor],borderWidth: 1,
      },
    ],
  };

  const options = {
    elements: {
      arc: {
        borderColor: (context) => {
          const total = successCount + failedCount;
          return total > 0 ? "transparent" : "#9b9a9a";
        },
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "start" as const,
      },
    },
  };
  return (
    <Box width={240} height={240} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {total > 0 ? (
        <Pie data={data} options={options} />
      ) : (
        <>
          <Box
            width={170}
            height={170}
            justifyContent={"center"}
            sx={{ border: "1px solid #9b9a9a", borderRadius: "100%", marginBottom: "1rem" }}
          ></Box>
          <Typography variant="body2" sx={{ textAlign: "center", fontSize: "0.8rem", color: "#9b9a9a" }}>
            No {labeltext} found for the selected period
          </Typography>
        </>
      )}
    </Box>
  );  
};

export default SuccessFailPieChart;
