import { SessionConfig } from '../../api/models';

function PayinReport(props) {
  const sessionConfig: SessionConfig = props.sessionConfig;

  const reportDataFilters = {
    merchant_id: sessionConfig.merchant.merchant_id,
  };

  const reportColumns = [
    { name: 'Client Code', type: 'metadata', value: '$.client_code' },
    { name: 'Billing Contact', type: 'builtin', value: 'name' },
    { name: 'Amount', type: 'builtin', value: 'amount' },
    { name: 'Status', type: 'builtin', value: 'status' },
    { name: 'Method', type: 'builtin', value: 'method' },
    { name: 'Mode', type: 'metadata', value: '$.transaction_mode' },
    { name: 'Created', type: 'builtin', value: 'created' },
  ];

  return (
    <rainforest-payment-report
      show-search
      display-header="Payments Report"
      session-key={sessionConfig.session.session_key}
      data-filters={JSON.stringify(reportDataFilters)}
      style-font-family='-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu'
      columns={JSON.stringify(reportColumns)}
    ></rainforest-payment-report>
  );
}

export default PayinReport;
