import {useMediaQuery, useTheme} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/kri/images/logo.webp';
import {useAuth} from '../../context';
import {AppBar, logoSmallStyle, logoStyle} from "./NavigationProps";

export default function Navigations() {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));

  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="absolute" sx={{ backgroundColor: '#0C415B' }}>
      <Toolbar
        sx={{
          pr: '24px',
        }}>
        <img
          src={isSmallerFormFactor ? logo : logo}
          style={isSmallerFormFactor ? logoSmallStyle : logoStyle}
          alt="APTFinancial"
          onClick={() =>
            auth && auth.accessToken
            && navigate('/')   
          }
        />
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}></Typography>
      </Toolbar>
    </AppBar>
  );
}
