import { Navigate } from 'react-router-dom';
import {axiosPrivate } from '../api/service';
import { useAuth } from '../context';

const PrivateRoute = ({ children }) => {
  const { auth } = useAuth();

  // const clientChannel = auth?.roles[0].split("::")[0];

  axiosPrivate.interceptors.request.use(
    (config) => {
      if (auth && auth.accessToken) {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
        // config.headers['x-client-channel'] = clientChannel;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (!auth || !auth.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
