import {
    Alert,
    FormControl, FormControlLabel, FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper, Radio, RadioGroup, Stack,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { operatorSelfPasswordReset } from '../../api/service';
import StickyFooter from '../../components/StickyFooter';
import { useAuth } from '../../context';
import { THEME_KRI } from '../../assets/kri/theme';
import AdminNavigations from "../../components/navigations/AdminNavigations";
import { PayinReport } from "../../components/rainforest";


const rfReceiptSmall = {
    textAlign: 'left',
    width: '90%',
};

// TODO - Rename to something else
const rfReceiptRegular = {
    textAlign: 'center',
};


export interface ProfileCreds {
    current_password?: string;
    password?: string;
    confirm_password?: string;
}


export default function UserProfile() {
    const { auth, setAuth } = useAuth();
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errors, setErrors] = useState<Partial<ProfileCreds>>({});

    const [open, setOpen] = useState(false);
    const [actionDisabled, setActionDisabled] = useState(false);

    const [profileCreds, setProfileCreds] = useState<ProfileCreds>({
        current_password: '',
        password: '',
        confirm_password: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        if (name === 'password') {
            const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
            if (!passwordRegex.test(value)) {
                error = 'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
            }
            else if (formattedValue.length > 33) {
                error = 'Password must be less than or equal to 32 characters.';
            }
            formattedValue = formattedValue.slice(0, 32);
        }

        if (name === 'confirm_password' && formattedValue != profileCreds.password) {
            console.log(formattedValue);
            error = 'The new password and confirm password entries do not match.';
            if (formattedValue.length > 33) {
                error = 'Password must be less than or equal to 32 characters.';
            }
            formattedValue = formattedValue.slice(0, 32);
        }

        setProfileCreds((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = () => {

        if (!profileCreds.current_password.trim() || !profileCreds.password.trim() || !profileCreds.confirm_password.trim()) {
            throw Error('Invalid passwords')
        }
        if (profileCreds.password.trim() != profileCreds.confirm_password.trim()) {
            throw Error('new and confirm password does not match')
        }
    }

    const passwordResetAsync = async () => {
        
        setErrorMessage(null);
        setSuccessMessage(null);
        validateForm();
        await operatorSelfPasswordReset(profileCreds.current_password.trim(), profileCreds.password.trim())
    };


    const handleOtherErrors = (error) => {
        let errorMessage = 'Failed to update the password. ';
        if (error.response && error.response.data) {
            errorMessage += `${error.response.data.message}. `;
        } else if (error) {
            errorMessage += `${error}. `;
        } else {
            errorMessage += 'Please try again. ';
        }
        errorMessage += 'Contact support if the problem persists.'
        setErrorMessage(errorMessage);
        setActionDisabled(false);
    };

    const handleLogout = () => {
        setAuth(null);
        localStorage.removeItem('auth')
        window.location.href = '/login';
    };

    const passwordResetErrorHandler = (error) => {
        if (error.response && error.response.data) {
            if (error.response.data.status_type === 'TOKEN_EXPIRED') {
                setErrorMessage(
                    `Failed to update the password: ${error.response.data.message}. 
                    You will be redirected to the sign-in page in 5 seconds.`
                );
                setTimeout(() => handleLogout(), 5000);
            } else {
                handleOtherErrors(error);
            }
            return;
        }
        handleOtherErrors(error);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Check if passwords meet the requirements
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$/;
        const newPassword = profileCreds.password.trim();
        const confirmNewPassword = profileCreds.confirm_password.trim();
    
        let newPasswordError = '';
        let confirmPasswordError = '';
    
        if (!passwordRegex.test(newPassword)) {
            newPasswordError = 'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
        }
    
        if (newPassword.length <= 8 || newPassword.length > 33) {
            newPasswordError = 'Password must be between 8 and 32 characters.';
        }
    
        if (newPassword !== confirmNewPassword) {
            confirmPasswordError = 'The new password and confirm password entries do not match.';
        }
    
        setErrors({
            password: newPasswordError,
            confirm_password: confirmPasswordError
        });
    
        // If any errors, do not proceed with the password reset
        if (newPasswordError || confirmPasswordError) {
            return;
        }
    
        // Call the password reset function if no errors
        setErrorMessage(null);
        setSuccessMessage(null);
        setActionDisabled(true);
    
        passwordResetAsync().then(() => {
            setSuccessMessage("Successfully updated the password. You will be redirected to the sign-in page in 5 seconds.");
            setTimeout(() => handleLogout(), 5000);
        }).catch((error) => passwordResetErrorHandler(error));
    };
    


    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Toolbar />
                    <Stack>
                        <Grid
                            container
                            columnSpacing={2}
                            justifyContent="center"
                        >

                            <Grid item sx={{ mt: 2, ml: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography variant="h4" textAlign={'left'}>
                                    Update Profile Password
                                </Typography>
                            </Grid>
                            <Grid item sx={{ ml: 1, mb: 2 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography textAlign={'left'} variant="body2">
                                    Please complete form below to update your password
                                </Typography>
                            </Grid>
                            <Grid item sx={{ m: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                {errorMessage && (
                                    <Alert variant="outlined" severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                                {successMessage && (
                                    <Alert variant="outlined" severity="success">
                                        {successMessage}
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item
                                component="form"
                                onSubmit={handleSubmit}
                                xs={isSmallerFormFactor ? 12 : 6}
                                sx={{ m: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="current_password"
                                    label="Current Password"
                                    name="current_password"
                                    type="password"
                                    size="small"
                                    onChange={handleChange}
                                    value={profileCreds.current_password}
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="New Password"
                                    type="password"
                                    size="small"
                                    onChange={handleChange}
                                    value={profileCreds.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirm_password"
                                    name="confirm_password"
                                    label="Confirm Password"
                                    type="password"
                                    size="small"
                                    onChange={handleChange}
                                    value={profileCreds.confirm_password}
                                    error={!!errors.confirm_password}
                                    helperText={errors.confirm_password}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={actionDisabled}
                                    sx={{ mt: 2, mb: 2 }}>
                                    Update Password
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
