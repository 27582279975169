import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { THEME_KRI } from '../assets/kri/theme';
import AdminNavigations from '../components/navigations/AdminNavigations';
import StickyFooter from '../components/StickyFooter';
import APTPaymentWizard from '../components/apt-wizard/APTPaymentWizard';
import { useAuth } from '../context';
import { AppBar, Container, Paper, Typography } from '@mui/material';
import { Image } from '@mui/icons-material';

export default function AdminAcceptPaymentPage() {

  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <AdminNavigations />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <Toolbar />
          <APTPaymentWizard />
        </Box>
        <Paper
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#0C415B',
            pt: 2,
            pb: 1,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            '@media (max-width: 600px)': {
              height: '50px',
            },
          }}
          component="footer"
          square
          variant="outlined">
          <StickyFooter />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
