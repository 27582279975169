import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Typography, Grid, Paper, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { operatorForgotPassword } from '../../api/service'
import { THEME_KRI } from '../../assets/kri/theme';
import AdminNavigations from '../../components/navigations/AdminNavigations';
import StickyFooter from '../../components/StickyFooter';

export default function ForgotPassword() {
    const theme = useTheme();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<{ [key: string]: string }>({});
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [emailError, setEmailError] = useState<any>('');

    const [isDisabled, setIsDisabled] = useState<boolean>(true)

    function validateEmailAddress(email: string): boolean {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
        return emailRegex.test(email);
    }
    const handleKeyDown = (e) => {
        const { value } = e.target;
        if (/[^a-zA-Z0-9.@]/.test(e.key)) {
            e.preventDefault();
        }
        setEmail(value);
    };
    const validateForm = (): boolean => {
        let newErrors: Partial<any> = {};

        if (!validateEmailAddress(email?.trim())) {
            newErrors.email = 'Please provide a valid email address';
        }

        setEmailError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsDisabled(false)
        validateForm();
    };

    const forgotPasswordAsync = async () => {
        try {
            const isValidForm = validateForm();
            if (!isValidForm) {
                return;
            }
            setSuccessMessage('');
            await operatorForgotPassword(email);
            setSuccessMessage('Password reset link shared on registered email address.');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === "User not found") {
                setError({ email: "User not found, Please enter the correct email address" });
            } else {
                console.error('An error occurred:', error);

            }
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmail('');
        setSuccessMessage('');
        await forgotPasswordAsync();
    };

    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: 'calc(100vh - 113px)' }}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Paper elevation={3} sx={{ padding: theme.spacing(4), overflow: 'hidden' }}>
                                {successMessage ?
                                    <Typography variant="body2" color={theme.palette.success.light} sx={{ mt: 1 }}>
                                        {successMessage}
                                    </Typography>
                                : error && Object.keys(error).map((key, index) => (
                                    <Typography key={index} variant="body2" color="error" sx={{ mt: 1 }}>
                                        {error[key]}
                                    </Typography>
                                ))}
                                
                                <Typography variant="h5" gutterBottom>
                                    Forgot Password
                                </Typography>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                    sx={{ mt: 1 }}>

                                    <TextField
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        margin="normal"
                                        value={email}
                                        onChange={handleEmailChange}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        required
                                        error={!!emailError.email}
                                        helperText={emailError.email}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isDisabled}
                                        sx={{ mt: 2 }}>
                                        Send Email
                                    </Button>
                                </Box>
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    Remembered your password? <Link to="/login">Login</Link>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
