import { Button, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AdminNavigations from "../components/navigations/AdminNavigations";
import StickyFooter from "../components/StickyFooter";
import { useAuth } from "../context";
import { THEME_KRI } from "../assets/kri/theme";
import { useEffect, useState } from "react";
import { User } from "../api/models";
import { getClientChannel } from "../api/service";
import "../App.css";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PaymentsIcon from "@mui/icons-material/Payments";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export default function AdminDashboardPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentUser, setCurrentUser] = useState<User>();
  const [clientChannelName, setClientChannelName] = useState<any>(null);
  const [clientChannelError, setClientChannelError] = useState("");

  useEffect(() => {
    const fetchClientChannel = async () => {
      try {
        const clientChannelData = await getClientChannel(auth.id);
        setClientChannelName(clientChannelData[0].client_channel_name);
      } catch (error) {
        setClientChannelError(
          error?.response?.data.message
            ? `Failed to fetch client channel ${error?.response?.data.message}`
            : "An unexpected error occurred"
        );
      }
    };

    fetchClientChannel();
  }, [auth.id]);

  function authChecker() {
    return (
      auth &&
      auth.roles &&
      (auth.roles.some((role) =>
        role.includes("5a86b9bf-58f0-4f7c-bcc2-5e99f63ed34f")
      ) ||
        auth.roles.some((role) =>
          role.includes("7f0ca276-6fba-4bee-a571-13549f6b4140")
        ))
    );
  }
  const isAuthenticated = authChecker();
  return (
    <ThemeProvider theme={THEME_KRI}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <CssBaseline />
        <AdminNavigations />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
            <Stack spacing={2}>
              <Grid
                container
                spacing={1}
                justifyContent={"flex-start"}
                direction="column"
              >
                <Grid item>
                  <Typography variant="h6" component="h6" textAlign={"left"}>
                    Hello {auth.username}!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign={"left"} variant="body2">
                    {/* Welcome to {clientChannelName? clientChannelName:clientChannelError}'s Patient Payments
                                        Portal, powered by Apt Financial */}
                    Welcome to the Apt Financial Payments Portal.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ gap: "50px", marginTop: "24px !important"}}
                justifyContent={"flex-start"}
              >
                <Grid item xs={isSmallerFormFactor ? false : false}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/accept-payment")}
                    className="dashboardButton"
                    sx={{display:"flex", flexDirection:"column"}}

                  >
                    <PaymentsIcon />
                    <Typography>Payments</Typography>
                  </Button>
                </Grid>
                <Grid item xs={isSmallerFormFactor ? false : false} >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/payment-report")}
                    className="dashboardButton"
                    sx={{display:"flex", flexDirection:"column"}}
                  >
                    <AssessmentIcon />
                    <Typography>Reports</Typography>
                  </Button>
                </Grid>
                {isAuthenticated && (
                  <Grid item xs={isSmallerFormFactor ? false : false}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/admin/create-user")}
                      className="dashboardButton"
                      sx={{display:"flex", flexDirection:"column"}}
                    >
                      <PersonAddIcon />
                      <Typography>Create User</Typography>
                    </Button>
                  </Grid>
                )}
                {auth &&
                  auth.roles.some((role) =>
                    role.includes("5a86b9bf-58f0-4f7c-bcc2-5e99f63ed34f")
                  ) && (
                    <Grid item xs={isSmallerFormFactor ? false : false}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/admin/customer/management")}
                        className="dashboardButton"
                        sx={{display:"flex", flexDirection:"column"}}
                      >
                        <ManageAccountsIcon />
                        <Typography>CUSTOMER MANAGEMENT</Typography>
                      </Button>
                    </Grid>
                  )}
                <Grid item xs={isSmallerFormFactor ? false : false}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/admin/dashboard")}
                    className="dashboardButton"
                    sx={{display:"flex", flexDirection:"column"}}
                  >
                    <SpaceDashboardIcon />
                    <Typography>Dashboard</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Container>
        </Box>
        <Paper
          sx={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            backgroundColor: "#0C415B",
            pt: 2,
            pb: 1,
            "@media (max-width: 600px)": {
              height: "50px",
            },
          }}
          component="footer"
          square
          variant="outlined"
        >
          <StickyFooter />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
