import {Home} from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LogoutIcon from '@mui/icons-material/Logout';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import PaymentsIcon from '@mui/icons-material/Payments';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme,} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/kri/images/logo.webp';
import {useAuth} from '../../context';
import {AppBar, logoSmallStyle, logoStyle} from "./NavigationProps";

export default function AdminNavigations() {
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const handleLogout = () => {
        console.log("AdminNavigations logged out");
        setAuth(null);
        localStorage.removeItem('auth')
        navigate('/login');
    };


    return (
        <>
            <AppBar
                position="absolute"
                open={open}
                sx={{backgroundColor: '#0C415B'}}>
                <Toolbar
                    sx={{
                        pr: '24px',
                    }}>
                    <img
                        src={isSmallerFormFactor ? logo : logo}
                        style={isSmallerFormFactor ? logoSmallStyle : logoStyle}
                        alt="APTFinancial"
                        onClick={() =>
                            auth && auth.accessToken
                                ? navigate('/')
                                : (window.location.href = '#')
                        }
                    />

                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}></Typography>
                    {auth && auth.username && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(!open)}
                            sx={{
                                marginRight: '0px',
                            }}>
                            {open ? <CloseIcon/> : <MenuIcon/>}
                        </IconButton>
                    )}
                </Toolbar>
                {auth && auth.accessToken && (
                    <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
                        <Box role="presentation" onClick={toggleDrawer(false)}>
                            <Toolbar
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    px: [1],
                                }}></Toolbar>
                            <Divider/>
                            <ListItem
                                component="div"
                                disablePadding
                                onClick={() => navigate('/user/profile')}
                            >
                                <ListItemButton sx={{height: 56}}>
                                    <ListItemIcon>
                                        <AccountCircleIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText>Hi {auth?.username}!</ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <Divider/>
                            <ListItem
                                component="div"
                                disablePadding
                                onClick={() => navigate('/admin')}>
                                <ListItemButton sx={{height: 56}}>
                                    <ListItemIcon>
                                        <Home color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Home"/>
                                </ListItemButton>
                            </ListItem>
                            <List component="nav">
                                <ListItemButton onClick={() => navigate('/accept-payment')}>
                                    <ListItemIcon>
                                        <PaymentsIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Payments"/>
                                </ListItemButton>
                                <ListItemButton onClick={() => navigate('/payment-report')}>
                                    <ListItemIcon>
                                        <AssessmentIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Reports"/>
                                </ListItemButton>
                                <ListItemButton onClick={() => navigate('/admin/dashboard')}>
                                    <ListItemIcon>
                                        <SpaceDashboardIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard"/>
                                </ListItemButton>
                                <ListItemButton onClick={() => navigate('/admin/help')}>
                                    <ListItemIcon>
                                        <HelpCenterIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Help"/>
                                </ListItemButton>
                               
                                <ListItem
                                    component="div"
                                    disablePadding
                                    onClick={handleLogout}>
                                    <ListItemButton sx={{height: 56}}>
                                        <ListItemIcon>
                                            <LogoutIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Log Out"/>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                )}
            </AppBar>
        </>
    );
}
