import { Alert, Container, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentTransaction } from '../../api/models';
import { RFPaymentResponse } from '../../api/rf_models';
import {
  getPaymentTransactionNoAuth,
  updatePaymentTransactionForRFValues,
} from '../../api/service';
import { useAuth } from '../../context';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const rfReceiptSmall = {
  textAlign: 'left',
};

const rfReceiptRegular = {
  textAlign: 'center',
};

export default function PayByLink() {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));

  const { auth } = useAuth();
  const rainforestPayment = useRef(null);
  const params = useParams();
  const [paymentTransaction, setPaymentTransaction] =
    useState<PaymentTransaction>(null);
  const [paymentSubmitSuccessful, setPaymentSubmitSuccessful] =
    useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [rfTransactionResponse, setRfTransactionResponse] =
    useState<RFPaymentResponse>(null);

  const getPaymentTransactionNoAuthAsync = async () => {
    setErrorMessages([]);
    try {
      setPaymentTransaction(
        (await getPaymentTransactionNoAuth(params.id)) as PaymentTransaction
      );
    } catch (error) {
      setErrorMessages((errorMessages) => [
        ...errorMessages,
        error?.response?.data.message
          ? error?.response?.data.message
          : 'An unexpected error occurred',
      ]);
    }
  };

  useEffect(() => {
    try {
      getPaymentTransactionNoAuthAsync();
    } catch (error) {}
  }, [params.id]);

  const handlePaymentApproval = async (paymentApproval: any) => {
    // setPaymentTransaction(null);
    setPaymentSubmitSuccessful(true);
    setRfTransactionResponse(paymentApproval.detail[0]?.data);
    try {
      await updatePaymentTransactionForRFValues(
        paymentTransaction.payment_transaction.transaction_id,
        paymentApproval.detail[0]?.data
      );
    } catch (error) {
      console.log(error);
      // This is a fire and forget event if there is exception, webhook will handle this
    }
  };

  const handlePaymentDeclined = async () => {
    console.log('PaymentDeclined');
  };

  const handlePaymentError = async () => {
    console.log('PaymentError');
  };

  const handleInvalidRFPayIn = async (e) => {
    console.log('PaymentError');
  };

  //   component.addEventListener('valid', (e) => {
  //     submit.removeAttribute('disabled');
  // });
  // component.addEventListener('invalid', (e) => {
  //     submit.setAttribute('disabled', true);
  // });

  useEffect(() => {
    const rfElement = rainforestPayment.current;

    if (rfElement) {
      console.log(rfElement);
      rfElement.addEventListener('approved', handlePaymentApproval);
      rfElement.addEventListener('declined', handlePaymentDeclined);
      rfElement.addEventListener('error', handlePaymentError);
      rfElement.addEventListener('invalid', handleInvalidRFPayIn);
    }

    return () => {
      if (rfElement) {
        rfElement.removeEventListener('approved', handlePaymentApproval);
        rfElement.removeEventListener('declined', handlePaymentDeclined);
        rfElement.removeEventListener('error', handlePaymentError);
        rfElement.addEventListener('invalid', handleInvalidRFPayIn);
      }
    };
  }, [paymentTransaction]);

  return (
    <>
      {paymentTransaction && paymentTransaction.merchant && (
        <Grid item sx={{ mt: 2 }}>
          <Typography textAlign={'left'} variant="body2">
              Welcome to {paymentTransaction.merchant.name}'s Patient Payments
              Portal, powered by Apt Financial
          </Typography>
        </Grid>
      )}
      <Grid item sx={{mt: 2}}>
        <>
        {paymentTransaction && !rfTransactionResponse && (
          <Container maxWidth="sm">
            <rainforest-payment
              ref={rainforestPayment}
              session-key={paymentTransaction.session.session_key}
              payin-config-id={
                paymentTransaction.payment_transaction.rf_payin_config_id
              }></rainforest-payment>
          </Container>
        )}
        {rfTransactionResponse && (
            <Container
                maxWidth="sm"
                sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
              <Alert variant="outlined" severity="success">
                Congratulations! Your payment has been successfully processed. Thank
                you for your prompt payment.
              </Alert>
              <rainforest-payin-receipt
                  session-key={paymentTransaction.session.session_key}
                  payin-id={
                    rfTransactionResponse.payin_id
                  }></rainforest-payin-receipt>
            </Container>
        )}
        </>
      </Grid>
    </>
  );
}
