import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getCustomerList } from '../../api/service';
import Button from '@mui/material/Button';



export default function CustomerList() {

    const [customerList, setCustomerList] =
        useState<any>(null);
    const [errorMessages, setErrorMessages] = useState([]);

    const getCustomerListAsync = async () => {
        setErrorMessages([]);
        try {
            setCustomerList(
                (await getCustomerList()) as any
            );
        } catch (error) {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                error?.response?.data.message
                    ? error?.response?.data.message
                    : 'An unexpected error occurred',
            ]);
        }
    };


    const columns: GridColDef[] = [
        { field: 'client_id', headerName: 'Client Id', width: 190, sortable: false },
        { field: 'client_name',  headerName: 'Client Name',width: 190,sortable: false},
        // { field: 'client_active', headerName: 'Client Active', width: 100, sortable: false},
        { field: 'client_channel_id', headerName: 'Channel Id', width: 170,sortable: false },
        { field: 'channel_name',headerName: 'Channel Name',sortable: false,width: 170},
        // { field: 'client_channel_active', headerName: 'Client Channel Active', width: 140,sortable: false },
        { field: 'rf_platform_id', headerName: 'Platform Id', width: 180,sortable: false },
        { field: 'rf_merchant_id', headerName: 'Merchant Id', width: 180,sortable: false }
    ];

    useEffect(() => {
        getCustomerListAsync();
    }, []);


    const rows = customerList ? customerList.map((customer, index) => ({
        ...customer,
        id: customer.client_channel_id
    })) : [];

    return (
        <div style={{ height: 400, width: '100%' }}>
            {errorMessages.length > 0 ? (
                <div>
                    {errorMessages.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            ) : customerList ? (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    getRowId={(row) => row.client_channel_id}
                />
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );

}
