import React, { useState } from 'react';
import { Button, TextField, Typography, Grid, Paper, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import { operatorResetPassword } from '../../api/service'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { THEME_KRI } from '../../assets/kri/theme';
import AdminNavigations from '../../components/navigations/AdminNavigations';
import StickyFooter from '../../components/StickyFooter';
import { useAuth } from '../../context';


export default function ResetPassword({ token }) {
    const { setAuth } = useAuth();

    const theme = useTheme();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    
    const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>('');

   
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPasswordValue = event.target.value;
        setNewPassword(newPasswordValue.slice(0, 32));
    
        if (newPasswordValue.length > 33) {
            setNewPasswordError('Password must be less than or equal to 32 characters.');
        } else if (!passwordRegex.test(newPasswordValue)) {
            setNewPasswordError('Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.');
        }
        else {
            setNewPasswordError('');
        }
    };
    
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPasswordValue = event.target.value;
        setConfirmPassword(confirmPasswordValue.slice(0, 32));

    
        if (confirmPasswordValue.length > 33) {
            setConfirmPasswordError('Password must be less than or equal to 32 characters.');
        } else if (newPassword !== confirmPasswordValue) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleLogin = () => {
        setAuth(null);
        localStorage.removeItem('auth')
        window.location.href = '/login';
    };

    const resetPasswordAsync = async (token, newPassword, confirmPassword) => {
        try {
            setSuccessMessage('');
            await operatorResetPassword(token, newPassword, confirmPassword);
            setDisableUpdateButton(true)
            setSuccessMessage(`New password updated successfully.
            Redirecting to the sign-in page in 5 seconds.`);
            setTimeout(() => handleLogin(), 5000);
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(`Failed to reset the password: ${error.response.data.message}.`);
            }
        }
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let newError = '';
        let confirmError = '';
    
        if (newPassword.trim() === '') {
            newError = 'New Password is required.';
        } else if (newPassword.length < 8 || newPassword.length > 32) {
            newError = 'Password must be between 8 and 32 characters.';
        } else if (!passwordRegex.test(newPassword)) {
            newError = 'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
        }
    
        if (confirmPassword.trim() === '') {
            confirmError = 'Confirmation password is required.';
        } else if (newPassword !== confirmPassword) {
            confirmError = 'Passwords do not match.';
        }
    
        setNewPasswordError(newError);
        setConfirmPasswordError(confirmError);
    
        if (!newError && !confirmError) {
            await resetPasswordAsync(token, newPassword, confirmPassword);
            setNewPassword('');
            setConfirmPassword('');
            setConfirmPasswordError('');
        }
    };
    
    return (

        <ThemeProvider theme={THEME_KRI}>
            <Box>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: 'calc(100vh - 113px)' }}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Paper elevation={3} sx={{ padding: theme.spacing(4) }}>
                                {successMessage ? (
                                    <Typography variant="body2" color={theme.palette.success.light} sx={{ mt: 1 }}>
                                        {successMessage}
                                    </Typography>
                                )
                                    : errorMessage && (
                                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                            {errorMessage}
                                        </Typography>
                                    )}
                                <Typography variant="h5" gutterBottom>
                                    Reset Password
                                </Typography>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                    sx={{ mt: 1 }}>

                                    <TextField
                                        label="New Password"
                                        type="password"
                                        fullWidth
                                        margin="normal"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        required
                                        error={newPasswordError !== ''}
                                        helperText={newPasswordError}
                                    />
                                    <TextField
                                        label="Confirm Password"
                                        type="password"
                                        fullWidth
                                        margin="normal"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                        error={confirmPasswordError !== ''}
                                        helperText={confirmPasswordError}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={disableUpdateButton}
                                        sx={{ mt: 2 }}>
                                            
                                        Update Password
                                    </Button>
                                </Box>

                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    Remembered your password? <Link to="/login">Login</Link>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
