import {
    Alert,
    FormControl, FormControlLabel, FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Paper, Stack,
    useMediaQuery,
    useTheme, OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createOperator, getAllClientChannel, getClientChannel, operatorSelfPasswordReset } from '../../api/service';
import StickyFooter from '../../components/StickyFooter';
import { useAuth } from '../../context';
import { THEME_KRI } from '../../assets/kri/theme';
import AdminNavigations from "../../components/navigations/AdminNavigations";
import { PayinReport } from "../../components/rainforest";
import { FormData } from "../../components/apt-wizard/APTPaymentWizard";


const rfReceiptSmall = {
    textAlign: 'left',
    width: '90%',
};

// TODO - Rename to something else
const rfReceiptRegular = {
    textAlign: 'center',
};


export interface CreateOperatorFormData {
    firstname?: string;
    lastname?: string;
    username?: string;
    email?: string;
    client_channel_id?: string;
}


export default function CreateOperatorPage() {
    const { auth, setAuth } = useAuth();
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errors, setErrors] = useState<Partial<CreateOperatorFormData>>({});
    const [actionDisabled, setActionDisabled] = useState(false);
    const [createOperatorFormData, setCreateOperatorFormData] = useState<CreateOperatorFormData>({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        client_channel_id: ''
    });

    const [clientChannelList, setClientChannelList] =
        useState<any>(null);
    const [selectedOption, setSelectedOption] = useState<string>(clientChannelList && clientChannelList[0].client_channel_id);
    const [clientChannelError, setClientChannelError] = useState('');

    function authCheckerForAdmin() {
        return auth && auth.roles && (
            auth.roles.some(role => role.includes('5a86b9bf-58f0-4f7c-bcc2-5e99f63ed34f'))
        );
    }
    const isAuthenticated = authCheckerForAdmin();
    useEffect(() => {
        const fetchClientChannel = async () => {
            try {
                let clientChannelData;
                if (isAuthenticated) {
                    clientChannelData = await getAllClientChannel();
                    setClientChannelList(clientChannelData);
                } else {
                    clientChannelData = await getClientChannel(auth.id);
                    setClientChannelList(clientChannelData);
                }
                if (clientChannelData.length === 1) {
                    setSelectedOption(clientChannelData[0].client_channel_id);
                }
            } catch (error) {
                setClientChannelError(
                    error?.response?.data.message
                        ? `Failed to fetch client channel ${error?.response?.data.message}`
                        : 'An unexpected error occurred'
                );
            }
        };

        fetchClientChannel();
    }, [auth.id]);

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        switch (name) {
            case 'firstname':
            case 'lastname':
            case 'username':
                if (/[^a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            case 'email':
                if (/[^a-zA-Z0-9.@]/.test(e.key)) {
                    e.preventDefault();
                }
                break;
            default:
                break;
        }

        setCreateOperatorFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));
    };
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setSelectedOption(e.target.value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        const { name, value } = e.target;
        let error = '';
        let formattedValue = value.trim();
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        switch (name) {
            case 'username':
            case 'firstname':
            case 'lastname':
                if (/[^a-zA-Z0-9\s]/.test(formattedValue)) {
                    error = 'Special characters are not allowed';
                } else if (formattedValue.length < 1) {
                    error = 'Field can not be empty';
                } else if (formattedValue.length > 40) {
                    error = 'Maximum 40 characters allowed';
                }
                formattedValue = formattedValue.slice(0, 40);
                break;
                case 'email':
                    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formattedValue)) {
                        error = 'Please provide a valid email address';
                    } else if (formattedValue.length > 100) {
                        error = 'Email must be less than 100 characters';
                    } else {
                        const [local, domain] = formattedValue.split('@');
                        if (local.length > 64 || (local + '@' + domain).length > 100) {
                            error = 'Email is too long';
                        }
                    }
                    formattedValue = formattedValue.slice(0, 100);
    
                    break;
            default:
                break;
        }

        setCreateOperatorFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
            client_channel_id: selectedOption
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    function validateEmailAddress(email: string): boolean {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
        return emailRegex.test(email);
    }

    const validateForm = (): boolean => {
        let newErrors: Partial<CreateOperatorFormData> = {};

        if (!createOperatorFormData.firstname.trim() || createOperatorFormData.firstname.trim().length > 40) {
            newErrors.firstname = 'First name field can not be empty or longer than 40 characters';
        } else if (createOperatorFormData.firstname.trim() && /[^a-zA-Z0-9\s]/.test(createOperatorFormData.firstname.trim())) {
            newErrors.firstname = 'Special characters are not allowed in First Name';
        }

        if (!createOperatorFormData.lastname.trim() || createOperatorFormData.lastname.trim().length > 40) {
            newErrors.lastname = 'Last name field can not be empty or longer than 40 characters';
        } else if (createOperatorFormData.lastname.trim() && /[^a-zA-Z0-9\s]/.test(createOperatorFormData.lastname.trim())) {
            newErrors.lastname = 'Special characters are not allowed in Last Name';
        }
        if (!createOperatorFormData.username.trim() || createOperatorFormData.username.trim().length > 40) {
            newErrors.username = 'User name field can not be empty or longer than 40 characters';
        } else if (createOperatorFormData.username.trim() && /[^a-zA-Z0-9\s]/.test(createOperatorFormData.username.trim())) {
            newErrors.username = 'Special characters are not allowed in User Name';
        }

        if (!createOperatorFormData.email?.trim()
            || createOperatorFormData.email?.trim().length > 250) {
            newErrors.email = 'Email field can not be empty or longer than 250 characters';
        }
        if (!validateEmailAddress(createOperatorFormData.email?.trim())) {
            newErrors.email = 'Please provide a valid email address';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOtherErrors = (error) => {
        let errorMessage = 'Failed to create user. ';
        if (error.response && error.response.data) {
            errorMessage += `Error: ${error.response.data.message}. `;
        } else if (error) {
            errorMessage += `Error: ${error}. `;
        } else {
            errorMessage += 'Please try again. ';
        }
        errorMessage += 'Contact support if the problem persists.'
        setErrorMessage(errorMessage);
        setActionDisabled(false);
    };

    const handleLogout = () => {
        setAuth(null);
        localStorage.removeItem('auth')
        window.location.href = '/login';
    };

    const transactionErrorHandler = (error) => {
        setActionDisabled(false);
        if (error.response && error.response.data) {
            if (error.response.data.status_type === 'TOKEN_EXPIRED') {
                setErrorMessage(
                    `Failed to update the password: ${error.response.data.message}. 
                    You will be redirected to the sign-in page in 5 seconds.`
                );
                setTimeout(() => handleLogout(), 5000);
            } else {
                handleOtherErrors(error);
            }
            return;
        }
        handleOtherErrors(error);
    }

    const createUserAsync = async () => {
        validateForm();
        console.log(createOperatorFormData);
        await createOperator(createOperatorFormData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        setActionDisabled(true);
        if (isAuthenticated && !selectedOption) {
            setClientChannelError('Please select a client channel');
            setActionDisabled(false);
            return; 
        }
        if (validateForm()) {
            createUserAsync().then(() => {
                setSuccessMessage("Successfully created the user and sent welcome email");
                setCreateOperatorFormData({
                    firstname: '',
                    lastname: '',
                    username: '',
                    email: '',
                    client_channel_id: ''
                });
                if(isAuthenticated){
                setSelectedOption('');
                setClientChannelError('');
                }
                setActionDisabled(false);

            }).catch((error) => transactionErrorHandler(error));
        } else {
            setActionDisabled(false);
        }
    };

    return (
        <ThemeProvider theme={THEME_KRI}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <CssBaseline />
                <AdminNavigations />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Toolbar />
                    <Stack>
                        <Grid
                            container
                            columnSpacing={2}
                            justifyContent="center"
                        >

                            <Grid item sx={{ mt: 2, ml: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography variant="h4" textAlign={'left'}>
                                    Create Admissions User
                                </Typography>
                            </Grid>
                            <Grid item sx={{ ml: 1, mb: 2 }} xs={isSmallerFormFactor ? 12 : 6}>
                                <Typography textAlign={'left'} variant="body2">
                                    Please complete form below to create new user
                                </Typography>
                            </Grid>
                            <Grid item sx={{ m: 1 }} xs={isSmallerFormFactor ? 12 : 6}>
                                {errorMessage && (
                                    <Alert variant="outlined" severity="error">
                                        {errorMessage}
                                    </Alert>
                                )}
                                {successMessage && (
                                    <Alert variant="outlined" severity="success">
                                        {successMessage}
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item
                                component="form"
                                onSubmit={handleSubmit}
                                xs={isSmallerFormFactor ? 12 : 6}
                                sx={{ m: 1 }}>
                                <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                    {clientChannelList && clientChannelList.length === 1 ? " " : (
                                        <InputLabel id="select-label">Client Channel</InputLabel>
                                    )}
                                    <Select
                                        labelId="select-label"
                                        id="select"
                                        required
                                        fullWidth
                                        value={selectedOption}
                                        onChange={handleSelectChange}
                                        label="Client Channel"
                                        disabled={clientChannelList && clientChannelList.length === 1}
                                    >
                                        {clientChannelList && clientChannelList.map((item) => (
                                            <MenuItem key={item.client_channel_id} value={item.client_channel_id}>{isAuthenticated ? item.name : item.client_channel_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {clientChannelError && <FormHelperText sx={{ color: '#f44336' }}>{clientChannelError}</FormHelperText>}
                                </FormControl>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstname"
                                    name="firstname"
                                    label="First Name"
                                    type="string"
                                    size="small"
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={createOperatorFormData.firstname}
                                    error={!!errors.firstname}
                                    helperText={errors.firstname}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastname"
                                    name="lastname"
                                    label="Last Name"
                                    size="small"
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={createOperatorFormData.lastname}
                                    error={!!errors.lastname}
                                    helperText={errors.lastname}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    size="small"
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={createOperatorFormData.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    name="username"
                                    label="User Name"
                                    size="small"
                                    onChange={handleChange}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    value={createOperatorFormData.username}
                                    error={!!errors.username}
                                    helperText={errors.username}
                                />


                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={actionDisabled}
                                    sx={{ mt: 2, mb: 2 }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
                <Paper
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#0C415B',
                        pt: 2,
                        pb: 1,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        '@media (max-width: 600px)': {
                            height: '50px',
                        },
                    }}
                    component="footer"
                    square
                    variant="outlined">
                    <StickyFooter />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
