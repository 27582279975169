import {
  Box,
  Container,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayByLinkResponse } from '../../api/models';
import { RFPaymentResponse } from '../../api/rf_models';
import Confirmation from './Confirmation';
import PatientDetails from './PatientDetails';
import Review from './Review';

const steps = ['Patient Details', 'Review', 'Confirmation'];

export interface FormData {
  first_name?: string;
  last_name?: string;
  client_code: string;
  notes: string;
  email: string;
  phone_number?: string;
  amount: string;
  transaction_mode: string;
  client_channel_id:string
}

export default function APTPaymentWizard() {
  const theme = useTheme();
  const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>(null);
  const [transactionResponse, setTransactionResponse] = useState<
    RFPaymentResponse | PayByLinkResponse
  >(null);
 


  const handleNext = (data?: any, response?: any) => {
    setFormData({ ...formData, ...data });
    setTransactionResponse(response);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousWithData = (data: any) => {
    setFormData(data);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    navigate('/admin');
  };

  const resetForm = () => {
    setFormData(null);
  };

  return (
    <Box
      sx={{ p: 4 }}
      display="flex"
      flexDirection={isSmallerFormFactor ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center">
      <Stack alignItems="stretch">
        <Grid container justifyContent="center" alignItems="center">
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            orientation="horizontal"
            style={{
              width: '100%',
              maxWidth: '400px',
              marginBottom: isSmallerFormFactor ? '1rem' : 0,
            }}>
            {steps.map((label, index) => (
              <Step key={label} >
                <StepLabel sx={{ fontSize: '24px' }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          {activeStep === 0 && (
            <PatientDetails onNext={handleNext} formData={formData} />
          )}
          {activeStep === 1 && (
            <Review
              onNext={handleNext}
              onPreviousWithData={handlePreviousWithData}
              formData={formData}
              resetForm={resetForm}
            />
          )}
          {activeStep === 2 && (
            <Confirmation
              onFinish={handleFinish}
              formData={formData}
              transactionResponse={transactionResponse}
            />
          )}
        </Grid>
      </Stack>
    </Box>
  );
}
