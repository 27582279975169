import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Modal,
  Box,
  IconButton,
  CircularProgress,
  Link,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomReportExport, GetClientBillingData, GetClientCodeData, GetBillingContactData } from "../../api/service";
import { PayinReport } from "../rainforest";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "5px",
  width: 400,
  display: "flex",
  flexDirection: "column",
  gap: "0.4rem",
};

const inputStyles = {
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputBase-input': {
    padding: '10px',
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  '& .MuiFormControl-root': {
    // marginTop: '8px',
    // marginBottom: '8px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
  },
};

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'medium',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '1rem',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: 'none',
            '&:hover': {
              border: 'none',
            },
            '&:focus': {
              border: 'none',
            },
            borderRadius: '5px',
          },
        },
      },
    },
  },
});

export default function APTPaymentReport(props) {
  const [open, setOpen] = useState(false);
  const [clientCode, setClientCode] = useState(null);
  const [billingContact, setBillingContact] = useState(null);
  const [mode, setMode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [noDataOpen, setNoDataOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clientCodes, setClientCodes] = useState([]);
  const [openClientCode, setOpenClientCode] = useState(false);
  const [billingContacts, setBillingContacts] = useState([]);
  const [openBillingContact, setOpenBillingContact] = useState(false);
  const [filteredBillingContacts, setFilteredBillingContacts] = useState([]);
  const [filteredClientCodes, setFilteredClientCodes] = useState([]);

  const fetchData = async () => {
    try {
      getClientCodes();
      getBillingContacts();
    } catch (error) {
      console.error("Failed to fetch client billing data:", error);
    }
  };

  const handleOpen = async () => {
    setOpen(true);
    await fetchData();
  };
  const handleClose = () => setOpen(false);
  const handleNoDataClose = () => setNoDataOpen(false);

  useEffect(() => {
    console.log("Use effect");

    if (open) {
      fetchData();
    }
  }, [open]);


  const handleCancel = () => {
    setClientCode(null);
    setBillingContact(null);
    setMode(null);
    setStartDate(null);
    setEndDate(null);
    setError('');
    handleClose();
    setFilteredClientCodes([]);
    setFilteredBillingContacts([]);
  };

  const validateDates = (start, end) => {
    if (start && end && new Date(start) > new Date(end)) {
      setError('Start date must not be later than the end date');
    } else {
      setError('');
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    validateDates(newStartDate, endDate);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    validateDates(startDate, newEndDate);
  };

  const handleExport = async () => {
    if (error) return; // Prevent export if there's a date error

    setLoading(true);

    const requestBody = {
      client_code: clientCode || null,
      billing_contact_name: billingContact || null,
      transaction_mode: mode || null,
      start_time: startDate ? new Date(startDate).toISOString() : null,
      end_time: endDate ? new Date(endDate).toISOString() : null,
    };

    try {
      const response = await CustomReportExport(requestBody);
      setLoading(false);

      if (!response.success) {
        setNoDataOpen(true);
      } else {
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      console.error("Export failed:", error);
    }
  };

  const getClientCodes = async () => {
    console.log("getClientCode function called");
    if (error) return;

    try {
      const response = await GetClientCodeData();
      if (response.success) {
        console.log("GetClientCodeData in tsx ", response);
        setClientCodes(response.data);
      }
    } catch (error) {
      console.error("GetClientCodeData failed:", error);
    }
  };

  const getBillingContacts = async () => {
    console.log("getBillingContactCode function called");
    if (error) return;

    try {
      const response = await GetBillingContactData();
      if (response.success) {
        console.log("GetBillingContactData in tsx ", response);
        setBillingContacts(response.data);
      }
    } catch (error) {
      console.error("GetBillingContactData failed:", error);
    }
  };

  const handleClientCodeChange = (event, newValue) => {
    setClientCode(newValue);

    if (newValue) {
      const selectedClient = clientCodes.find((code) => code.client_code === newValue);
      const customerId = selectedClient?.customer_id;

      const filteredContacts = billingContacts.filter((contact) => contact.customer_id === customerId);

      setFilteredBillingContacts(filteredContacts);
    } else {
      setFilteredBillingContacts([]);
    }
  }

  const handleBillingContactChange = (event, newValue) => {
    setBillingContact(newValue);

    if (newValue) {
      const selectedContact = billingContacts.find((contact) => contact.full_name === newValue);
      const customerId = selectedContact?.customer_id;

      const filteredCodes = clientCodes.filter((code) => code.customer_id === customerId);

      setFilteredClientCodes(filteredCodes);
    } else {
      setFilteredClientCodes([]);
    }
  }

  const handleArrowClick = (setOpen) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleInputClick = (setOpen) => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
        <Stack spacing={2}>
          <Grid container spacing={1} justifyContent={"flex-start"} direction="column">
            <Grid item sx={{ mt: 0, ml: 1 }}>
              <Typography variant="h4" textAlign={"left"}>
                Reports
              </Typography>
            </Grid>
            <Grid item sx={{ ml: 1 }} >
              <Typography textAlign={"left"} variant="body2" sx={{ fontSize: "1rem" }}>
                Select a payment record to view details, cancel or refund a
                payment.
              </Typography>
            </Grid>
            <Grid item sx={{ ml: 1 }}>
              <Typography textAlign={"left"} variant="body2" sx={{ fontSize: "1rem" }} >
                Click
                <Link onClick={handleOpen} sx={{ textDecoration: 'none', cursor: 'pointer' }}> here </Link>
                to export transaction history
              </Typography>
            </Grid>
            <Grid item>
              {props.sessionConfig &&
                props.sessionConfig.session &&
                props.sessionConfig.merchant && (
                  <PayinReport sessionConfig={props.sessionConfig} />
                )}
            </Grid>
          </Grid>
        </Stack>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Export Custom Report
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Box component="form" sx={{
              mt: 2, display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" sx={{ maxWidth: '200px', margin: '0px' }}>
                    <TextField
                      label="Start Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      value={startDate}
                      onChange={handleStartDateChange}
                      sx={inputStyles}
                      error={Boolean(error)}
                      helperText={error && "Start date must be earlier than the end date"}
                      inputProps={{
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" sx={{ margin: '0px' }}>
                    <TextField
                      label="End Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      value={endDate}
                      // onChange={(e) => setEndDate(e.target.value)}
                      onChange={handleEndDateChange}
                      sx={inputStyles}
                      error={Boolean(error)}
                      helperText={error && "Start date must be earlier than the end date"}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    value={clientCode}
                    onChange={handleClientCodeChange}
                    options={filteredClientCodes.length ? filteredClientCodes.map((code) => code.client_code) : clientCodes.map((code) => code.client_code)}
                    open={openClientCode}
                    onOpen={() => setOpenClientCode(true)}
                    onClose={() => setOpenClientCode(false)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Code"
                        variant="outlined"
                        size="small"
                        onClick={() => handleInputClick(setOpenClientCode)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <ArrowDropDownIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleArrowClick(setOpenClientCode);
                                }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}
                    fullWidth
                    freeSolo
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    value={billingContact}
                    onChange={handleBillingContactChange}
                    options={filteredBillingContacts.length ? filteredBillingContacts.map((contact) => contact.full_name) : billingContacts.map((contact) => contact.full_name)}
                    open={openBillingContact}
                    onOpen={() => setOpenBillingContact(true)}
                    onClose={() => setOpenBillingContact(false)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Billing Contact"
                        variant="outlined"
                        size="small"
                        onClick={() => handleInputClick(setOpenBillingContact)} // Only open the dropdown on input click
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <ArrowDropDownIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the input from also receiving the click event
                                  handleArrowClick(setOpenBillingContact);
                                }}
                              />
                            </>
                          ),
                        }}
                      />
                    )}
                    fullWidth
                    freeSolo // Allows for filtering and custom input
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="mode-select-label" sx={{ fontSize: '0.875rem', top: '-5px' }}>Payment Mode</InputLabel>
                    <Select
                      labelId="mode-select-label"
                      id="mode-select"
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      label="Payment Mode"
                      fullWidth
                      sx={inputStyles}
                    >
                      <MenuItem value="all" sx={{ fontSize: '0.875rem' }}>All</MenuItem>
                      <MenuItem value="pay-now" sx={{ fontSize: '0.875rem' }}>Pay Now</MenuItem>
                      <MenuItem value="pay-by-link" sx={{ fontSize: '0.875rem' }}>Pay By Link</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="center" spacing={2} sx={{ mt: 0 }}>
              <Grid item>
                <Button variant="contained" onClick={handleCancel} sx={{ minWidth: '100px', minHeight: '40px' }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>

                <Button variant="contained" onClick={handleExport}
                  disabled={loading || Boolean(error)} // Disable if loading or there's a date error
                  sx={{ minWidth: '100px', minHeight: '40px' }}>
                  {loading ? <CircularProgress size={24} /> : "Export"}
                </Button>

                {/* <Button variant="contained" onClick={handleExport} disabled={loading} sx={{ minWidth: '100px', minHeight: '40px' }}>
                  {loading ? <CircularProgress size={24} /> : "Export"}
                </Button> */}
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={noDataOpen} onClose={handleNoDataClose}>
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
              No data found with the given criteria.
            </Typography>
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <Button onClick={handleNoDataClose} variant="contained">
                Close
              </Button>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </ThemeProvider>
  );
}
