import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import {AuthProvider, useAuth} from './context';
import AdminAcceptPaymentPage from './pages/AdminAcceptPaymentPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import PayByLinkPage from './pages/PayByLinkPage';
import SignIn from './pages/user-account/SignIn';
import AdminReportPage from './pages/AdminReportPage';
import ReceiptPage from './pages/ReceiptPage';
import AdminHelpPage from './pages/AdminHelpPage';
import UserProfile from "./pages/user-account/UserProfile";
import CreateOperatorPage from "./pages/user-account/CreateOperatorPage";
import CustomerManagement from './pages/customer-account/CustomerManagement';
import CreateCustomerPage from './pages/customer-account/CreateCustomerPage';
import ForgotPassword from './pages/user-account/ForgotPassword';
import ResetPassword from './pages/user-account/ResetPassword';
import DashboardPage from "./pages/user-account/DashboardPage";

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <AdminDashboardPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/admin"
                            element={
                                <PrivateRoute>
                                    <AdminDashboardPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                           path="/user/profile"
                           element={
                                <PrivateRoute>
                                    <UserProfile />
                                </PrivateRoute>
                        }
                        />
                        <Route
                            path="/accept-payment"
                            element={
                                <PrivateRoute>
                                    <AdminAcceptPaymentPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/payment-report"
                            element={
                                <PrivateRoute>
                                    <AdminReportPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/admin/help"
                            element={
                                <PrivateRoute>
                                    <AdminHelpPage/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/admin/create-user"
                            element={
                                <PrivateRoute>
                                    <CreateOperatorPage />
                                </PrivateRoute>
                            }
                        />
                          <Route
                            path="/admin/customer/management"
                            element={
                                <PrivateRoute>
                                    <CustomerManagement />
                                </PrivateRoute>
                            }
                        />
                         <Route
                            path="/admin/create/customer"
                            element={
                                <PrivateRoute>
                                    <CreateCustomerPage />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        {/* TODO: Should be-->/reset/:token */}
                        <Route path="/reset/password/:token" element={<ResetPasswordWrapper />}/>

                        <Route path="/pay/:id" element={<PayByLinkPage/>}/>
                        <Route path="/receipt/:id" element={<ReceiptPage/>}/>
                        <Route path="/login" element={<SignIn/>}/>
                        <Route
                            path="/admin/dashboard"
                            element={
                                <PrivateRoute>
                                    <DashboardPage />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

function ResetPasswordWrapper() {
    const { token } = useParams();
    return <ResetPassword token={token} />;
}
export default App;
