import {Container, Grid, useMediaQuery, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PaymentTransaction} from '../../api/models';
import {getPaymentTransactionNoAuth} from '../../api/service';
import Typography from "@mui/material/Typography";

const rfReceiptSmall = {
    textAlign: 'left',
};

const rfReceiptRegular = {
    textAlign: 'center',
};

export default function Receipt() {
    const theme = useTheme();
    const isSmallerFormFactor = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const params = useParams();
    const [paymentTransaction, setPaymentTransaction] =
        useState<PaymentTransaction>(null);
    const [errorMessages, setErrorMessages] = useState([]);

    const getPaymentTransactionNoAuthAsync = async () => {
        setErrorMessages([]);
        try {
            setPaymentTransaction(
                (await getPaymentTransactionNoAuth(params.id)) as PaymentTransaction
            );
        } catch (error) {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                error?.response?.data.message
                    ? error?.response?.data.message
                    : 'An unexpected error occurred',
            ]);
        }
    };

    useEffect(() => {
        try {
            if (!paymentTransaction) {
                getPaymentTransactionNoAuthAsync();
            }
        } catch (error) {
        }
    }, [params.id]);

    useEffect(() => {
        try {
            if (
                paymentTransaction &&
                paymentTransaction.payment_transaction &&
                !paymentTransaction.payment_transaction.rf_payin_id
            ) {
                navigate(`/pay/${params.id}`);
            }
        } catch (error) {
        }
    }, [paymentTransaction, paymentTransaction?.payment_transaction]);

    return (
        <>
            {paymentTransaction && paymentTransaction.merchant && (
                <Grid item sx={{ mt: 2 }}>
                    <Typography textAlign={'left'} variant="body2">
                        Welcome to {paymentTransaction.merchant.name}'s Patient Payments
                        Portal, powered by Apt Financial
                    </Typography>
                </Grid>
            )}
            <Grid item sx={{mt: 2}}>
                <Container
                    maxWidth="sm"
                    sx={isSmallerFormFactor ? rfReceiptSmall : rfReceiptRegular}>
                    {paymentTransaction &&
                        paymentTransaction.session &&
                        paymentTransaction.payment_transaction.rf_payin_id && (
                            <rainforest-payin-receipt
                                session-key={paymentTransaction.session.session_key}
                                payin-id={
                                    paymentTransaction.payment_transaction.rf_payin_id
                                }></rainforest-payin-receipt>
                        )}
                </Container>
            </Grid>
        </>
    );
}
